import React, { Component } from 'react';
import Lottie from 'react-lottie';
import Extralottie1Data from '../../lottie/28812-scroll-down-animation.json';

class Extralottie1 extends Component {


  render(){

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: Extralottie1Data,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div className="scrolllottiediv">
        <Lottie options={defaultOptions}
              height={100}
              width={45}
        />
      </div>
    )
  }
}

export default Extralottie1
// this is the scrolling down lottie