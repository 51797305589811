import React, { Component } from 'react';
import Lottie from 'react-lottie';
import Extralottie3Data from '../../lottie/4975-question-mark.json';

class Extralottie3 extends Component {


  render(){

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: Extralottie3Data,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div>
        <Lottie options={defaultOptions}
              height={400}
              width={400}
        />
      </div>
    )
  }
}

export default Extralottie3