import React from 'react'
import {XYPlot, LineSeries, ContourSeries, ArcSeries, AreaSeries, HeatmapSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis} from 'react-vis';


export default function GraphCardDisplay({title, subtitle, lastUpdate, graphType, data}) {

  return (
    <div className={`p-4 max-w-xs w-auto bg-gray-700 rounded-xl flex flex-col items-center justify-center`}>
        <div className={``}>
            <XYPlot height={300} width={300}>      
                   {graphType.toLowerCase() === "line" && <LineSeries data={data} />}
                   {graphType.toLowerCase()  === "heatmap" && <HeatmapSeries data={data} />}
                   {graphType.toLowerCase()  === "contour" && <ContourSeries data={data} />}
                   {graphType.toLowerCase()  === "arc" && <ArcSeries data={data} />}
                   {graphType.toLowerCase()  === "area" && <AreaSeries data={data} />}
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <XAxis />
                    <YAxis />         
            </XYPlot>
        </div>
        <div className={`flex items-start justify-center flex-col gap-2`}>
            <div className={`flex items-start justify-center flex-col gap-2 text-gray-100 border-b pb-2 border-b-gray-100 border-opacity-50`}>
                <span className={`text-lg font-sans font-bold`}>{title}</span>
                <span>{subtitle}</span>
            </div>
            <div className={`flex items-center justify-start gap-2`}>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-100" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <span className={`text-gray-200`}>{lastUpdate}</span>
            </div>
        </div>
    </div>
  )
}
