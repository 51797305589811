import React, { Component } from 'react';
import Lottie from 'react-lottie';
import HelpGrowData from '../../lottie/36258-help-grow.json';

class HelpGrow extends Component {


  render(){

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: HelpGrowData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div>
        <Lottie options={defaultOptions}
              height={400}
              width={400}
        />
      </div>
    )
  }
}

export default HelpGrow