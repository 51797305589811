import '../../css/defaultherosection.css';

import React, {useRef, useEffect, useState, Component} from 'react';



export default function defaultherosection(props) {
  return (
    <div className="hero-section-default w-full h-auto flex flex-col items-center justify-center p-12 lg:p-20">
                   
    <div className="hero-title-wrapper  flex flex-col items-center justify-center h-40 w-full">
      <h1 className="w-full text-center font-sans text-4xl lg:text-7xl text-white">{props.title}</h1>
      {
      window.location.href.includes('sobrenos') && (
      props.location.state ?  
      props.location.state.fromProfessionalSide &&       
                
     <div className="mt-4 flex items-center justify-center">
     <h1 className="text-lg text-gray-100 w-auto text-center p-2 border-2 border-white"> Você + Oportunidades = QuemIndiq</h1>
     </div>
     :
     <div className="mt-4 flex items-center justify-center">
     <h1 className="text-lg text-gray-100 w-auto text-center p-2 border-2 border-white"> Seus Objetivos + Economia + Qualidade + Velocidade = QuemIndiq </h1>
     </div>
     )

     }
     
     
      </div>

</div>

  )
}
