import React from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

export default function SelfPerfilOptionSelector({description, questionId, setprogress, progress, arrayOfOptions,answers, setanswers}) {
    
    const [checked, setchecked] = useState({
        radioA1: false,
        radioB1: false,
        radioA2: false,
        radioB2: false,
        radioA3: false,
        radioB3: false,
        radioA4: false,
        radioB4: false,
    })

    const [oldChecked, setoldChecked] = useState({
        radioA1: false,
        radioB1: false,
        radioA2: false,
        radioB2: false,
        radioA3: false,
        radioB3: false,
        radioA4: false,
        radioB4: false,
    })

    
    useEffect(() => {

        // loop through the object "checked" to see where are true values
        for (let key in checked) {
            if (checked[key] === true) {
               // extract the second to last digit of the key
                let questionSubAnswerIdNumber = key.substring(key.length - 1)
                let questionLetter = key.substring(key.length - 2, key.length - 1)        
                let choicePosition = 0
                let subQuestionId = questionSubAnswerIdNumber

                switch (questionLetter) {
                    case "A":
                        choicePosition = 1
                        break;
                    case "B":
                        choicePosition = 2
                        break;
                    default:
                        break;
                }
                // get the answers as of right now
                let temporaryAnswerArray = [...answers]
                // search it for an object with questionId equal to "questionId"
                let temporaryAnswerObject = temporaryAnswerArray.find(element => element.questionId === questionId)
                // find index of temporaryAnswerObject
                let temporaryAnswerObjectIndex = temporaryAnswerArray.indexOf(temporaryAnswerObject)                     
                // if temporaryAnswerObjectIndex is not -1, update the temporaryAnswerObject with the new choicePosition
                if (temporaryAnswerObjectIndex !== -1) {
                    let oldFirstChoice = temporaryAnswerArray[temporaryAnswerObjectIndex].firstChoice
                    let oldSecondChoice = temporaryAnswerArray[temporaryAnswerObjectIndex].secondChoice
                    if (choicePosition === 1) {
                        if (subQuestionId === oldSecondChoice){
                            temporaryAnswerArray[temporaryAnswerObjectIndex].secondChoice = 0
                        }
                        temporaryAnswerArray[temporaryAnswerObjectIndex].firstChoice = subQuestionId
                    } else {
                        if (subQuestionId === oldFirstChoice){
                            temporaryAnswerArray[temporaryAnswerObjectIndex].firstChoice = 0
                        }
                        temporaryAnswerArray[temporaryAnswerObjectIndex].secondChoice = subQuestionId
                    }             
                    
                } else {
                    // if temporaryAnswerObjectIndex is -1, add the new object to the temporaryAnswerArray
                    let questionIdAndChoicePosition = {
                        questionId: questionId,
                        firstChoice: 0,
                        secondChoice: 0,                   
                    }
                    if (choicePosition === 1) {
                        questionIdAndChoicePosition.firstChoice = subQuestionId
                    } else {
                        questionIdAndChoicePosition.secondChoice = subQuestionId
                    }
                    temporaryAnswerArray.push(questionIdAndChoicePosition)
                }        
                                
                setanswers(temporaryAnswerArray)
                console.log(answers)
                     
            }
        }

        // check how many options from checked are true
        let count = 0
        for (let key in checked) {
            if (checked[key]) {
                count++
            }
        }

        let oldCount = 0
        for (let key in oldChecked) {
            if (oldChecked[key]) {
                oldCount++
            }
        }
             
        switch (count - oldCount) {   
            case -1:
                setprogress(progress - 1)          
                break;
            case 0:              
                break;   
            case 1:
                setprogress(progress + 1)            
                break;   
            default:           
                break;
          
        }
        
        setoldChecked(checked)
    }, [checked])
    
 
    const listOfPossibleRadioNumbers = [1,2,3,4]
    const produceObjectWithoutRadioConflictsForSelfPerfil = (radioName, oppositeName)=>{

         // finds the second to last digit of the radio Name
        const letter = radioName.charAt(radioName.length-2)
        // finds if there is a number in the radio name and gets it
        const number = radioName.match(/\d/g)
        // find the index of the variable number in listOfPossibleRadioNumbers
        const indexOfNumber = listOfPossibleRadioNumbers.indexOf(Number(number))
        
        // produce an array identical to listOfPossibleRadioNumbers but without the item in the indexOfNumber
        const listOfPossibleRadioNumbersWithoutConflicts = listOfPossibleRadioNumbers.filter((_,index)=>{
            return index !== indexOfNumber
        })

     

      
      
        const newObject = {...checked} 
        newObject[radioName] = true     
        if (newObject[radioName] === checked[oppositeName]) {
            alert('Atenção! Você não pode selecionar duas opções opostas! Sua opção oposta foi deselecionada!')            
        }
        newObject[oppositeName] = false
      
          // run a foreachloop on listOfPossibleRadioNumbersWithoutConflicts and produce an object with the radioNames        
        console.log(listOfPossibleRadioNumbersWithoutConflicts)
          listOfPossibleRadioNumbersWithoutConflicts.forEach((item)=>{
            newObject[`radio${letter}${item}`] = false
        })
        
        return newObject
        
    }

    const handleRadioClick = (e,radioName)=>{    
       

        if (radioName.includes('A')){
            let nameOfOppositeRadioToBecomeFalse = radioName.replace('A','B')
            let object = produceObjectWithoutRadioConflictsForSelfPerfil(radioName,nameOfOppositeRadioToBecomeFalse)
            setchecked(object)  
        } else {
            let nameOfOppositeRadioToBecomeFalse = radioName.replace('B','A')
            let object = produceObjectWithoutRadioConflictsForSelfPerfil(radioName,nameOfOppositeRadioToBecomeFalse)
            setchecked(object)  
        }
    }

 
    return(
    <div className={`relative flex flex-col items-center justify-center pt-12 rounded-md border-gray-400 border-2`}>    
          <div className={`absolute top-2 text-gray-600 right-4 text-lg font-bold`}>
              #{questionId}
          </div>     
          {description && <p className={`px-2 text-gray-700`}>{description}:</p>}
          <div className={`flex w-full items-center justify-end pr-4 gap-6`}>
              <div className={`text-red-800 `}>Mais</div>
              <div className={`text-red-800`}>Menos</div>
          </div>
         
      <div className={` flex w-full flex-col items-center justify-center`}>
            {arrayOfOptions.map((element, index) => {
                return(
                   <div key={element.id}>
                        <FormControl>     
                            <div className={`w-[280px] -my-2 p-2 flex items-center justify-between gap-4`}>
                                <div className={`w-[140px] flex items-center justify-start`}><FormLabel>{element.option}</FormLabel></div>
                                <RadioGroup
                                row   
                                >
                                    <div className={`flex items-center justify-center gap-4 -mr-4`}>
                                        <FormControlLabel  value="mais" control={<Radio color={"error"} onChange={(e)=>{handleRadioClick(e,"radioA"+[index+1])}} checked={checked["radioA"+[index+1]]} />} label="" />
                                        <FormControlLabel  value="menos" control={<Radio color={"error"} onChange={(e)=>{handleRadioClick(e,"radioB"+[index+1])}} checked={checked["radioB"+[index+1]]} />} label="" />     
                                    </div>
                                </RadioGroup>
                            </div>
                        </FormControl>
                   </div>
                )
            })}       
      </div>
    </div>
  )
}
