import '../css/contato.css';

import React, {componentDidMount} from 'react';
import DefaultHeroSection from '../components/contentcomponents/defaultherosection.js';
import ThatPixFooter from '../components/standalone/ThatPixFooter';
import ContactForm1 from '../components/contentcomponents/ContactForm1';
import ContactForm2 from '../components/contentcomponents/ContactForm2';
import ContactForm3 from '../components/contentcomponents/ContactForm3';
import ContactForm4 from '../components/contentcomponents/ContactForm4';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'


class ContatoPage extends React.Component { 
 


  constructor(props){
    super(props);  
    this.state={
      correctFormCode: 0,    
      selectorTitle: "Escolha Aqui",

    }
       
}



  
componentDidMount(){  

}

componentDidUpdate(){
  
}


  render(){ 

    const correctForm = [];    

    if (this.state.correctFormCode === 0 ) {
      correctForm.push ([]);      
    }

    if (this.state.correctFormCode === 1 ) {
      correctForm.push (<ContactForm1 key={this.state.correctFormCode}/>);      
    }

    if (this.state.correctFormCode === 2 ) {
      correctForm.push (<ContactForm2 key={this.state.correctFormCode}/>);      
    }

    if (this.state.correctFormCode === 3 ) {
      correctForm.push (<ContactForm3 key={this.state.correctFormCode}/>);      
    }

    if (this.state.correctFormCode === 4 ) {
      correctForm.push (<ContactForm4 key={this.state.correctFormCode}/>);      
    }

   

    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }
    
  return (
    <div className="ContatoPage w-screen h-auto overflow-x-hidden flex items-center flex-col justify-center">
       
          <DefaultHeroSection title="Contato"/> 

          

          <div className="form-w lg:w-1/2 w-3/4 mt-8">
            <div className="headline-w flex items-center justify-center pt-20 pb-20">
                 <h1 className="text-6xl text-center font-sans">Olá! Qual o objetivo do seu contato?</h1>

            </div>
                   
               <div className="dropdown-w ">

               <Menu as="div" className="relative inline-block text-left w-full">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-8 bg-white text-3xl md:text-4xl  font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
              {this.state.selectorTitle}
              <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            show={open} 
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="w-full  origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              <div className="py-1 ">
              <Menu.Item>
                  {({ active }) => (
                    <a           
                    onClick={()=>{
                      this.setState({correctFormCode: 1})
                      this.setState({selectorTitle: "Quero Contratar um Profissional"})
                      
                    }
                    }                                           
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-8 text-3xl md:text-4xl'
                      )}
                    >
                      Quero Contratar um Profissional
                    </a>
                  )}
                </Menu.Item>
              <Menu.Item>
                  {({ active }) => (
                    <a        
                       
                    onClick={()=>
                      {this.setState({correctFormCode: 2})
                      this.setState({selectorTitle: "Tenho uma Dúvida"})
                   
                    }}                                           
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-8 text-3xl md:text-4xl'
                      )}
                    >
                      Tenho uma Dúvida
                    </a>
                  )}
                </Menu.Item>
              
                <Menu.Item>
                  {({ active }) => (
                    <a                     
                    onClick={()=>{
                      this.setState({correctFormCode: 3})
                      this.setState({selectorTitle: "Suporte Técnico"})
                    
                    }}           
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900 ' : 'text-gray-700 ',
                        'block px-4 py-8 text-3xl md:text-4xl'
                      )}
                    >
                      Suporte Técnico
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => ( 
                    <a   
                      onClick={()=>{
                        this.setState({correctFormCode: 4})
                        this.setState({selectorTitle: "Outros"})
                        
                      }}                  
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-8 text-3xl md:text-4xl'
                      )}
                    >
                      Outros
                    </a>
                  )}
                </Menu.Item>
               
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
      
                </div>

                

                 <div id="contact-form-box">   
                 {correctForm}
                 </div>         
                
                

   
          </div>
          <ThatPixFooter />

    </div>   
         
     
   
         
  
  );
}
}

export default ContatoPage;
