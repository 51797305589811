// This react component is what is called on the home page to load the
// content that is only to be seen by professionals. 
import React, {useRef, useEffect, useState} from 'react';
import '../../../css/TestimonialSection.css';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import {default as Carousel2} from 'react-elastic-carousel';


function TestimonialSection(props) {

var testimonialcarouselbg = "primary";  
var testimonialbox = document.getElementsByClassName("dftflex1");   
var testimonialtitle = document.getElementsByClassName("testimonialtitle");     
var bgvar1 = null;

  function slideBgChange() {

          if (testimonialcarouselbg == "primary")
        {
          bgvar1="#ee3635"; 
          testimonialbox = document.getElementsByClassName("dftflex1");    
          testimonialtitle = document.getElementsByClassName("testimonialtitle");      
          
          for (let index = 0; index < testimonialbox.length; index++) {
            testimonialbox[index].style.backgroundColor = bgvar1;      
            testimonialbox[index].style.backgroundColor = bgvar1;          
          }    

          for (let index = 0; index < testimonialtitle.length; index++) {
            testimonialtitle[index].style.color = bgvar1;    
            testimonialtitle[index].style.borderColor = bgvar1;              
          }                  
         
          testimonialcarouselbg = "secondary";
              
        }
        else
        {
          bgvar1="#9a8d75"; 
          testimonialbox = document.getElementsByClassName("dftflex1");     
          testimonialtitle = document.getElementsByClassName("testimonialtitle");
          
          for (let index = 0; index < testimonialbox.length; index++) {
           testimonialbox[index].style.backgroundColor = bgvar1;  
            
          }

          for (let index = 0; index < testimonialtitle.length; index++) {
          
          testimonialtitle[index].style.color = bgvar1;          
          testimonialtitle[index].style.borderColor = bgvar1;   
            
          }

            
         
          testimonialcarouselbg = "primary";    
         
        }                          
          
       
        
    };

    
const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];




  return (
    <div className="TestimonialSection">
      <div className="ContentWrapper">

      <Container className="Container">

       <div className="titlesectionholder2">

<h1 className="testimonialtitle mt-16" id="testimonialtitle">Garantimos Excelência</h1>

</div>

              <div className="dftflex1" id="dftflex1"> 

                        <div className="width50percent">
                        <h2>Reconhecimento no Mercado</h2>
                                          <p>As idealizadoras e criadoras da QuemIndiq tem muita história para contar. Alguns testemunhos de quem já trabalhou, conhece e confirma seus perfis de  reconhecida competência no mercado Educacional e de Seleção de Talentos.</p>
                                   
                                          <a href="https://app.quemindiq.com.br/cadastro"><button className="button2">Quero me Cadastrar</button></a>
                        </div>

                        <Carousel className="Carousel"
                        id="BgChangingCarousel"      
                        onSlide={slideBgChange}                
                        >
                        <Carousel.Item >
                        <div className="dftslide">
                        <div className="TestPhoto" id="monicabicalho"></div>                                                 
                        <h3>MONICA BICALHO</h3>
                        <h5>Diretora Executiva - GB Serviços Educacionais</h5>
                        <div className="quotationmarks"></div>
                        <p>Conectar com a Quemindiq é garantia de serviço eficiente. Conheço a competência de Márcia Goulart e Gilma Correa, entregam com qualidade sem perder a sensibilidade.</p>
                        </div>               
                    
                        </Carousel.Item>

                        <Carousel.Item >
                        <div className="dftslide">
                        <div className="TestPhoto" id="vanessagayo"></div>                                                 
                        <h3>VANESSA GAYO </h3>
                        <h5>Coordenadora Pedagógica – Ensino Fundamental 1&2</h5>
                        <div className="quotationmarks"></div>
                        <p>Projeto incrível que vem auxiliando nossa classe de educadores para a obtenção de novas escolhas e oportunidades. Tal idéia só poderia surgir de duas referências no ramo da educação. Grata pela sensibilidade em dar luz a novos sonhos e horizontes repletos de possibilidades.</p>
                        </div>               
                    
                        </Carousel.Item>

                        <Carousel.Item >
                        <div className="dftslide">
                        <div className="TestPhoto" id="rogeriopimenta"></div>                                                 
                        <h3>ROGÉRIO PIMENTA</h3>
                        <h5>Professor Universitário & Consultor</h5>
                        <div className="quotationmarks"></div>
                        <p>Posso atestar que Gilma e Márcia apresentam larga e sólida experiência profissional no mercado corporativo e educacional, com entrega de soluções eficientes com foco no cliente. Esse é o grande diferencial delas: compreender pessoas e seu impacto nos negócios.</p>
                        </div>               
                    
                        </Carousel.Item>

                        <Carousel.Item >
                        <div className="dftslide">
                        <div className="TestPhoto" id="andrecorval"></div>                                                 
                        <h3>ANDRÉ CORVAL</h3>
                        <h5>Gerente de Operações Especiais</h5>
                        <div className="quotationmarks"></div>
                        <p>Márcia e Gilma possuem bagagem e experiências profissionais que definitivamente vão alavancar a seleção de talentos e apoiar as movimentações de carreiras na área de Educação.</p>
                        </div>               
                    
                        </Carousel.Item>

                        <Carousel.Item >
                        <div className="dftslide">
                        <div className="TestPhoto" id="claudiabarros"></div>                                                 
                        <h3>CLÁUDIA BARROS</h3>
                        <h5>Diretora Executiva do Instituto Apontar</h5>
                        <div className="quotationmarks"></div>
                        <p>Tive o prazer e o privilégio de trabalhar e aprender muito com as duas, não só sobre Educação e Gestão, mas acima de tudo sobre pessoas. Profissionais que inspiram!</p>
                        </div>               
                    
                        </Carousel.Item>

                        <Carousel.Item >
                        <div className="dftslide">
                        <div className="TestPhoto" id="adeliacastro"></div>                                                 
                        <h3>ADÉLIA CASTRO</h3>
                        <h5>Diretora de Parcerias Educacionais e Relacionamento</h5>
                        <div className="quotationmarks"></div>
                        <p>Apaixonadas por educação há mais de 30 anos. Professoras e gestoras experientes e reconhecidas pelo trabalho ético, consistente e motivador na formação e desenvolvimento profissional de pessoas. 
                         Por onde passam deixam a marca da competência inquestionável. QuemIndiq tem a marca da paixão de quem sabe fazer e desenvolver.
                         </p>
                        </div>               
                    
                        </Carousel.Item>

                        <Carousel.Item>
                        <div className="dftslide">
                        <div className="TestPhoto" id="mariaalice"></div>                                                 
                        <h3>MARIA ALICE ANTUNES</h3>
                        <h5>Diretora do Instituto de Letras – UERJ</h5>
                        <div className="quotationmarks"></div>
                        <p>Márcia e Gilma são experts no comportamento humano. Sabem revelar o que cada um tem de melhor!</p>
                        </div>               
                    
                        </Carousel.Item>

                        <Carousel.Item >
                        <div className="dftslide">
                        <div className="TestPhoto" id="danielescaleira"></div>                                                 
                        <h3>DANIEL ESCALEIRA</h3>
                        <h5>Sócio e Membro do Conselho APDZ Educação e Tecnologia</h5>
                        <div className="quotationmarks"></div>
                        <p>Nada como contar com profissionais sérios e que acompanham o processo de recrutamento e seleção do início ao fim, nos dando a certeza de que estamos com o candidato certo para o que a empresa realmente necessita. Foi profissionalismo, excelência no atendimento e muita experiência que encontrei na Quemindiq! Eu indico!</p>
                        </div>               
                    
                        </Carousel.Item>

                        <Carousel.Item >
                        <div className="dftslide">
                        <div className="TestPhoto" id="cristinadaibert"></div>                                                 
                        <h3>CRISTINA DAIBERT</h3>
                        <h5>Consultora em Gestão Educacional</h5>
                        <div className="quotationmarks"></div>
                        <p>QuemIndiq! Conhecimento e Paixão Conectando a Educação!</p>
                        </div>               
                    
                        </Carousel.Item>
                       
                      </Carousel>

            </div> 


            <div className="titlesectionholder1">

<h1>Muitas Opções</h1>

</div>
      
      <div className="listofopenings">     
       <Carousel2 breakPoints={breakPoints}>

          <div className="gen-car-opt">
            <div className="titleheaderx">
            <div className="iconprofessor titleicon-x"></div>
            <h2>Professores:</h2>
            </div>
          
          <p>•	Bilíngues (inglês, espanhol,francês,alemão) <br></br>
•	Educação Infantil<br></br>
•	Ensino Fundamental 1 e 2<br></br>
•	Ensino Médio<br></br>
•	EAD<br></br>
•	Advisor <br></br>
•	Universitário<br></br>
•	Libras<br></br>
</p>
       
          </div>
         
          <div className="gen-car-opt2">
          <div className="titleheaderx">
            <div className="icongestaoescolar titleicon-x"></div>
            <h2>Gestão Escolar:</h2>
            </div>
          
          <p>•	Diretor / Gerente  Pedagógico  <br></br>
•	Diretor/ Gerente  Administrativo <br></br>
•	Gestão de Operações / Diretor de Operações <br></br>
•	Gestão de Inovação ( gestor, coordenador) <br></br>
•	Coordenador Pedagógico   <br></br>
•	Orientador Educacional <br></br>
•	Administrador Escolar <br></br>
•	Secretário Escolar <br></br>
•	Bibliotecário  <br></br>
</p>          
          </div>
         
          <div className="gen-car-opt">
          <div className="titleheaderx">
            <div className="iconinfrasuporte titleicon-x"></div>
            <h2>Infra Estrutura  de Suporte Administrativo:</h2>
            </div>
     
          <p>•	Gerente de Unidade <br></br>
•	Equipe de RH – Gente & Gestão (gestor, coordenador,analista,assistente)<br></br>
•	Equipe  Financeira (gestor, coordenador,analista,tesoureiro)<br></br>
•	Gestão de TI ( gestor, analista / produtos digitais)<br></br>
•	Inspetor – Auxiliar de Disciplina<br></br>
•	Recepcionista <br></br>
•	Serviços Gerais <br></br>
</p>
        
          </div>
         
          <div className="gen-car-opt2">
          <div className="titleheaderx">
            <div className="iconinframarketing titleicon-x"></div>
            <h2>Infra Estrutura Marketing & Comercial:</h2>
            </div>
          
          <p>•	Customer Success / Customer Experience- (Relacionamento com escolas/ com clientes / com alunos)<br></br>
•	Analista Comercial (Inside Sales)<br></br>
•	Suporte ao Aluno Admissão, Matrícula (Coordenador/ Analista) <br></br>
•	Gestão de marketing (Consultor, analista, especialista)<br></br>
•	Mktg digital, Inteligência de Mercado – (Business Inteligence)<br></br>
•	Gestão de Inovação / Crescimento<br></br>
</p>
          
          </div>
         
          <div className="gen-car-opt">
          <div className="titleheaderx">
            <div className="iconeditoras titleicon-x"></div>
            <h2>Editoras:</h2>
            </div>
         
          <p>•	Editor<br></br>
          •	Autor<br></br>
          •	Tradutor<br></br>
          •	Desenvolvedor de conteúdo<br></br>
          •	Preparador<br></br>
          •	Revisor Crítico<br></br>
          •	Revisor de Conteúdo<br></br>
          •	Revisor Linguístico  (inglês / francês / espanhol)<br></br>
          •	Coordenador de Projetos<br></br>
          •	Coordenador Editorial <br></br>
          •	Consultor Acadêmico<br></br>
          •	Product owner (Gerente de Produto)<br></br>

</p>
         
          </div>
         
          <div className="gen-car-opt2">
          <div className="titleheaderx">
            <div className="iconoutrosprofissionais titleicon-x"></div>
            <h2>Outros Profissionais:</h2>
            </div>
          
          <p>•	Auxiliar da Educação Infantil <br></br>
•	Nutricionista <br></br>                                            
•	Recreador <br></br>
•	Estagiário<br></br>
•	Trainee<br></br>
•	Instrutor<br></br>
•	Business Partner<br></br>
</p>
        
          </div>
         
         

        </ Carousel2> 

<a className="awrapperofbuttons" href={props.cadastreseLink}><button className="df-button" id="carousel-button1">{props.buttonName}</button></a>
      </div>

  </Container>

  
      


      </div>

    </div>
  );
}

export default TestimonialSection;
