// This react component is what is called on the home page to load the
// content that is only to be seen by professionals. 
import React, {useRef, useEffect, useState} from 'react';
import '../../../css/Professionalhomecontent.css';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import TestimonialSection from '../home/TestimonialSection';
import Button from 'react-bootstrap/Button';
import PuzzleLottie from '../../lottiecomponents/PuzzleLottie';
import LockedLottie2 from '../../lottiecomponents/Locked';
import ExtraLottie2 from '../../lottiecomponents/Extralottie2';
import ExtraLottie3 from '../../lottiecomponents/Extralottie3';
import ExtraLottie4 from '../../lottiecomponents/Extralottie4';
import ExtraLottie5 from '../../lottiecomponents/Extralottie5';
import HelpGrow from '../../lottiecomponents/helpgrow';
import {TweenMax, Power3, gsap, Linear, TimelineMax} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {default as Carousel} from 'react-bootstrap/Carousel';
import 'react-multi-carousel/lib/styles.css';
import ThatPixFooter from '../../standalone/ThatPixFooter';
import {useNavigate} from 'react-router-dom'

export default function Professionalhomecontent() {
      const navigate = useNavigate()  

      const toSobreNosFromProfessional = () =>{   
            navigate('/sobrenos', {fromProfessionalSide: true})
         }

  return (
     <div className="Professionalhomecontent2 flex items-center justify-center flex-col">

      
    <div className="triggerboxforlogo"></div>       
              <div className="fixedlogo2"></div>  
    
    
            
    
              <div className="z z1 backgroundbody"></div>
         
        <div className="toload"></div>        
           
              <TestimonialSection buttonName="Cadastre-se!" cadastreseLink="/manutencao" />
              
              <div className="temoshistoria dftsection" >  
    
    <div className="titlesectionholder1">
    
    <h1>Temos História</h1>
          
          </div>
    
    
    <Carousel id="carouseltemoshistoria1x"> 
    
               <Carousel.Item interval={10000}>          
          <div className="leftright" id="marcialeftright">
                
    
               <div className="left">
               <h2>"Temos História"</h2>
                        <div className="textcontent">
                        
                              <div className="leftright2">
                                    <div className="left">
                                          <div className="quotationmark"></div>
                                    </div>
                                    <div className="right">
                                    
                                    <p>Minha vida profissional foi exclusivamente dedicada à área Educacional
    com maior ênfase no ensino de língua estrangeira, gerenciamento de equipes e
    implementação de programas bilingues em escolas parceiras. Com essa vivência, ficou
    muito claro que o profissional da Educação precisa de apoio para conhecer as
    oportunidades de movimentação de carreira.</p>
    <p>-Márcia Marques Goulart, Co-Fundadora</p>
          
                                    </div>
                                                      
                                   
                              </div>
                            
                        </div>    
                       <button onClick={()=>toSobreNosFromProfessional()}  className="button1">Quero Saber Mais</button>
                  </div>
                  <div className="right">
                  <div className="picturewrapper">
                        <div className="marciapicture"></div>
                        <div className="floterboxbehindpicture"></div>
    
                        </div>
    
           </div>
    
    </div>       
    </Carousel.Item>
    
    <Carousel.Item interval={10000}>          
          <div className="leftright" id="gilmaleftright">
    
               <div className="left">
               <h2>"Você precisa se tornar visível ao mercado"</h2>
                        <div className="textcontent">
                              <div className="leftright2">
                                    <div className="left">
                                          <div className="quotationmark"></div>
                                    </div>
                                    <div className="right">
                                  
                                    <p>Escolhi, por paixão e vocação, trabalhar na área de Educação, iniciando como
    professora e ocupando diversos cargos ao longo de duas décadas. Posteriormente,
    trilhei o caminho de gestão de talentos na área de Recursos Humanos na América
    Latina e concluí este ciclo com uma certeza: nosso segmento de educação, os
    profissionais que trabalham ou querem trabalhar e se dedicar a esta área precisam de
    suporte para se tornarem visíveis ao mercado. </p>
    <p>-Gilma Silva Correa, Co-Fundadora</p>
                             
                                    
                                    </div>
                              </div>
                            
                        </div>    
                        <button onClick={()=>toSobreNosFromProfessional()} className="button1">Quero Saber Mais</button>
                  </div>
                  <div className="right">
                         
                        <div className="picturewrapper">
                        <div className="gilmapicture"></div>
                        <div className="floterboxbehindpicture"></div>
                        </div>
                       
    
           </div>
    
    </div>       
    </Carousel.Item>
    
    
    
    </Carousel> 
    
    </div>
    
    
    
    
    
    <div className="tudoem4passos dftsection">       
    
    <div className="titlesectionholder2">
    
    <h1>Tudo em 4 Passos</h1> 
          
          </div>   
          <div className="leftright">
          <div className="left">
                        <div className="textcontent">
                              <h2>Sempre Simples e Fácil</h2>
                              <p>1-	Você se identifica; <br></br>
    2-	Registra suas qualificações e história profissional; <br></br>
    3-	Descreve suas aspirações de carreira; <br></br>
    4-	Realiza sua autoavaliação de perfil comportamental (opcional). <br></br>
    </p>
                             
                             <button onClick={()=>toSobreNosFromProfessional()} className="button2">Quero Saber Mais</button>
                        </div>    
                  </div>
                  <div className="right">
                  <PuzzleLottie />
           </div>
    </div>
    </div>
    
    
    
    <div className="cadaprofissional dftsection">  
    
    <div className="titlesectionholder2">
    
      <h1>Conectamos Diretamente</h1> 
    
      </div>    
          <div id="conectamosDiretamenteId" className="leftright">
          <div className="left">
                        <div className="textcontent">
                              <h2>Em um processo humano e pessoal</h2>
                              <p>1-	Buscamos vagas com o seu perfil</p>
                              <p>2-	Conectamos você às melhores instituições</p>
                             
                              <a href="/manutencao"><button className="button2">Quero me conectar</button></a>
                        </div>    
                  </div>
                  <div className="right">
                  <HelpGrow />
            
           </div>
    </div>
    </div>
    
    <div className="somos3itens dftsection">  
    
    <div className="titlesectionholder2">
    
    <h1>Nosso Serviço é...</h1> 
    
    </div>    
    <div className="leftright3">
    <div className="left">
              <div className="textcontent">
                    <h2>Tudo que você sempre quis</h2>
                    <p>Ao se conectar, você automaticamente constrói seu Currículo Profissional.</p>                         
                   
                    <a href="/manutencao"><button className="button2">Quero ser QuemIndiq!</button></a>
              </div>    
        </div>
        <div className="right">
        <div className="threecolumns">
              <div className="column">
                    <div className="freeicon"></div>
                    <h1>Grátis</h1>
              </div>
              
              <div className="column">
                    <div className="relaxicon"></div>
                    <h1>Fácil</h1>
              </div>
              <div className="column">
                    <div className="fasticon"></div>
                    <h1>Rápido</h1>
              </div>
              
        </div>
    
    </div>
    </div>
    </div>
    
    
    
    
    <div className="NossoServico dftsection">
    <div id="titleSeguroConfiavelId" className="titlesectionholder2">
    
    <h1>Nosso Diferencial</h1> 
    
    </div>    
    
          <div id="seguroConfiavelId" className="leftright">
          <div className="left">
                        <div className="textcontent">
                              <h2>Direto & Preciso</h2>
                              <p>Vamos atrás daquilo que você busca economizando seu tempo e priorizando sempre seus objetivos.</p>
                             
                              <button onClick={()=>toSobreNosFromProfessional()} className="button2">Quero Saber Mais</button>
                        </div>    
                  </div>
                  <div className="right">
                  <ExtraLottie5 />
             
           </div>
    </div>
    </div>
    
    
    
    <div className="ConectamosDiretamente dftsection">
    <div className="titlesectionholder2">
    
    <h1>Sob Medida & Sob Demanda</h1> 
    
    </div>    
          <div className="leftright">
          <div className="left">
                        <div className="textcontent">
                              <h2>Comece o Processo para Acharmos sua Instituição Ideal!</h2>
                              <p>Conectamos a comunidade educacional proporcionando visibilidade e valorização dos profissionais e excelência na atração e seleção de talentos para as Instituições do segmento de Educação.</p>
                             
                              <a href="/manutencao"><button className="button2">Quero Saber Mais</button></a>
                        </div>    
                  </div>
                  <div className="right">
                      <ExtraLottie4 />
    
           </div>
    </div>
    </div>
    
    
    
    <div className="Confidencialeseguro dftsection">      
    <div className="titlesectionholder2">
    
    <h1>Seguro & Confiável</h1> 
    
    </div>       
            <div className="z z1"></div>
            <div className="z z2"></div>
            <div className="z z3"></div>
                          
    
          <div className="leftright">
         
                  <div className="left">
                        <div className="textcontent">
                              <h2>Seguro & Confiável!</h2>
                              <p>1- Garantimos total CONFIDENCIALIDADE dos dados cadastrados.</p>
                              <p>2- Todo processo seletivo é conduzido de forma sigilosa.</p>
                              <p>3- Plataforma confiável e com Certificação de Segurança.</p>
                             
                              <a href="/contato"><button className="button2">Contato QuemIndiq!</button></a>
                        </div>    
                  </div>
                  <div className="right">
                    <LockedLottie2 />
                    
    
                  </div>
    </div>
    </div>
    
    
    
    <div className="FrequentlyAskedQuestions dftsection">
    <div className="titlesectionholder1">
    
    <h1>Se Você ainda Tem Dúvidas...</h1> 
    
    </div>    
    
    <div className="z z1"></div>
    <div className="z z2"></div>
    <div className="z z3"></div>
    
    <div className="updown">
            <div className="up">
            <div className="leftright">
                         
                         <div className="left">
                               <div className="textcontent">
                                     <h2>Nos Pergunte e Responderemos!</h2>
                                     <p>Buscamos responder todas as suas dúvidas e perguntas! </p>
                                    
                                     <a href="/contato"><button className="button1">Quero Perguntar!</button></a>
                               </div>    
                         </div>
                         <div className="right">
                           <ExtraLottie3 />
                           
          
                         </div>
           </div>                        
            </div>
            <div className="down">
                  <div className="questionwrapper">
                              
    <Accordion defaultActiveKey="0">
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="0">
    1- O que é a QUEMINDIQ? 
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="0">
      <Card.Body>É uma plataforma idealizada por educadores com mais de 30 anos de história profissional e que tem como principal foco apoiar e conectar profissionais dentro do segmento de Educação na sua busca por movimentos de carreira e desenvolvimento profissional.</Card.Body>
    </Accordion.Collapse>
    </Card>
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="1">
    2- Como construir meu CV QuemIndiq?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="1">
      <Card.Body>Essa sessão é a  ferramenta que nos possibilita conhecer seu perfil, sua história profissional e suas aspirações para identificarmos possíveis conexões. Preencha-o com bastante carinho e atenção e registre todas as informações que considerar relevante.</Card.Body>
    </Accordion.Collapse>
    </Card>
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="2">
    3- Quem terá acesso a meus dados ?
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="2">
      <Card.Body>Pelo nosso <a href="https://admin.quemindiq.com.br/static/documents/termo_de_uso_quem_indiq.pdf">Termo de uso e  Confidencialidade</a> você nos autoriza disponibilizar seus dados profissionais  única e exclusivamente para que possamos indicar seu perfil para posições que tenham aderência.  </Card.Body>
    </Accordion.Collapse>
    </Card>
    <Card>
    <Accordion.Toggle as={Card.Header} eventKey="3">
    <div className="customdiv1"><p>4- O que é o SELFperfil </p><div className="selfperfillogo1"></div><p>?</p></div>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey="3">
      <Card.Body>Desenvolvemos essa  auto avaliação guiada com o objetivo  de apoiar seu auto conhecimento e encorajar seu auto desenvolvimento contínuo e consistente. Com base em suas respostas, um relatório individual e confidencial é gerado,  identificando  as percepções de seu perfil comportamental e ressaltando os soft skills mais destacados.  </Card.Body>
    </Accordion.Collapse>
    </Card>
    </Accordion>
    <a href="/contato"><button className="button1">Tenho Outras Dúvidas!</button></a>
            
    </div>
            </div>
    
    
    
    </div>
    
    
    
    </div>
    
    <ThatPixFooter />          
    
        </div>
  )
}

