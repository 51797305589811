// This react component is what is called on the home page to load the
// content that is only to be seen by institutions. 

//all window.load are here

import React, {useRef, useEffect, useState} from 'react';
import '../../../css/Institutionhomecontent.css';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import TestimonialSection from '../home/TestimonialSection';
import Button from 'react-bootstrap/Button';
import PuzzleLottie from '../../lottiecomponents/PuzzleLottie';
import LockedLottie2 from '../../lottiecomponents/Locked';
import ExtraLottie2 from '../../lottiecomponents/Extralottie2';
import ExtraLottie3 from '../../lottiecomponents/Extralottie3';
import ExtraLottie4 from '../../lottiecomponents/Extralottie4';
import ExtraLottie5 from '../../lottiecomponents/Extralottie5';
import HelpGrow from '../../lottiecomponents/helpgrow';
import {TweenMax, Power3, gsap, Linear, TimelineMax} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {default as Carousel} from 'react-bootstrap/Carousel';
import 'react-multi-carousel/lib/styles.css';
import ThatPixFooter from '../../standalone/ThatPixFooter';


function Institutionhomecontent() {

      gsap.registerPlugin(ScrollTrigger);

      var testimonialstate = 0;
      var tlfloater1 = gsap.timeline ({repeat: -1});
      var tlfloater2 = gsap.timeline ({repeat: -1});
      var tlfloater3 = gsap.timeline ({repeat: -1});
      var tlfloater4 = gsap.timeline ({repeat: -1});
      var tlfloater5 = gsap.timeline ({repeat: -1});
      var tlfloater6 = gsap.timeline ({repeat: -1});
      var tlfloater7 = gsap.timeline ({repeat: -1});
      var tlbgzao = gsap.timeline ({repeat: -1});
      var tlpageloader = gsap.timeline ({repeat: 0});
      var tllogoscroll = gsap.timeline ({repeat: 0});


      testimonialstate = Math.floor(Math.random()*2)   

      var tl = new TimelineMax({repeat:0});

      
      var actionNav = gsap.to(['.fixedlogo2', '.fixedlogo'], {x:280, duration:1, ease:'power3.in', paused:true});
  
      // Animation of the passing stuff for the authors
      
      
    
      // Start the scroll movement calculation.
      // The goal is to have a way to know how much the scroll bar
      // has scrolled in the past fraction of second or so. 
      var setInterval1= setInterval(ScrollDistance1,100);
      var setInterval2= setInterval(ScrollDistance2,100);
      var scrollbary1 = 0;
      var scrollbary2 = 0;     
      var scrolldiff = 0;

      function ScrollDistance1(){            
            scrollbary1=window.scrollY;           
      }

      function ScrollDistance2(){            
           setTimeout(function(){scrollbary2=window.scrollY},100);
           scrolldiff = Math.abs(scrollbary2 - scrollbary1);       
           }
      //end of the scroll movement calculation


      ScrollTrigger.create({
            trigger: ".triggerboxforlogo",
            start: "top top",            
            end: 9999999,        
            onUpdate: ({progress, direction, isActive}) => {
                   if (direction == -1 && scrolldiff > 250) {
                    actionNav.reverse()     
                    console.log (actionNav.progress())         
                  } if (direction == 1) {
                    actionNav.play()
                  } else if (direction == 1 && isActive == true) {
                    actionNav.play()
                  }
                }
          });

      function floater1 () {

            tlfloater1.to(".floater1", {x:120+"vw", duration: 20, ease: Linear.easeNone});
            tlfloater2.to(".floater2", {x:120+"vw", duration: 40, ease: Linear.easeNone});
            tlfloater3.to(".floater3", {x:120+"vw", duration: 80, ease: Linear.easeNone});
            tlfloater4.to(".floater4", {x:120+"vw", duration: 120, ease: Linear.easeNone});
            tlfloater5.to(".floater5", {rotation:-360, duration: 5, ease: Linear.easeNone});
            tlfloater6.to(".floater6", {rotation:360, duration: 5, ease: Linear.easeNone});
            tlfloater7.to(".floater7", {x:120+"vw", delay: 0, duration: 10, ease: Linear.easeNone});
            tlbgzao.to(".bgzao", {backgroundPosition: "200px 0px", duration: 20, ease: Linear.easeNone});

}

  
    function backgroundmoving1 ()
            {                 
            tl.to(".backgroundbody", 50, { 
            backgroundPosition: "-200px 0px",                       
            repeat: -1,          
            yoyo:true,  
            ease: Linear.easeNone,      
            });           
            }        

    function pageloader ()
            {                 
            tlpageloader.to(".logopageloader", {  
            y: 20,                           
            opacity:1,
            duration: 1,
            ease: Power3.easeOut,      
            });   
                           
            tlpageloader.to(".pageloaderh3", {   
             y: 20,                    
             opacity:1,
             duration: 1,
             delay: -0.5,
             ease: Power3.easeOut,      
             });   

             tlpageloader.to(".pageloader", {   
                  y: 100+"vh",                 
                  duration: 1,                  
                  delay: 0,
                  ease: Power3.easeInOut,      
                  });   

              tlpageloader.to(".pageloader", {   
                        x: -100+"vw",                 
                        duration: 0,                  
                        delay: 0,                         
                        });   

            
            
            

            }   

      window.onload = function () {      
           
                   
            pageloader();
            backgroundmoving1();
            floater1();         
          
      }  

        
    

  return (
    <div className="Institutionhomecontent2 flex items-center justify-center flex-col">

          <div className="triggerboxforlogo"></div>       
          <div className="fixedlogo2"></div>  


        

          <div className="z z1 backgroundbody"></div>
       
     
         <TestimonialSection buttonName="Procuro Talentos" cadastreseLink="/contato" />



          <div className="temoshistoria dftsection" >  

          <div className="titlesectionholder1">

          <h1>Temos Historia</h1>
                
                </div>
          
          
          <Carousel id="carouseltemoshistoria1"> 
          
          <Carousel.Item interval={10000}>          
      <div className="leftright" id="marcialeftright">
            

           <div className="left">
           <h2>"O fundamental é a escolha de um profissional de
excelência."</h2>
                    <div className="textcontent">
                    
                          <div className="leftright2">
                                <div className="left">
                                      <div className="quotationmark"></div>
                                </div>
                                <div className="right">
                                
                                <p>Em minha experiência profissional ao longo de mais de 30 anos de história com
exclusividade no segmento de Educação, ficou muito claro que a peça fundamental
para boa implementação dos processos educacionais é a escolha de um profissional de
excelência.</p>

<p>-Márcia Marques Goulart, Co-Fundadora</p>
      
                                </div>
                                                  
                               
                          </div>
                        
                    </div>    
                   <button onClick={()=>document.location.href='/sobrenos'}  className="button1">Quero Saber Mais</button>
              </div>
              <div className="right">
              <div className="picturewrapper">
                    <div className="marciapicture"></div>
                    <div className="floterboxbehindpicture"></div>

                    </div>

       </div>

</div>       
</Carousel.Item>

<Carousel.Item interval={10000}>          
      <div className="leftright" id="gilmaleftright">

           <div className="left">
           <h2>"Pessoas são o pilar do crecimento de qualquer Instituição"</h2>
                    <div className="textcontent">
                          <div className="leftright2">
                                <div className="left">
                                      <div className="quotationmark"></div>
                                </div>
                                <div className="right">
                              
                                <p>Meu maior aprendizado ao longo de décadas de minha trilha na Educação e em Gestão
de Gente é que pessoas são, inquestionavelmente, o pilar principal da
sustentabilidade, excelência e crescimento de qualquer Instituição, em especial no
segmento educacional.</p>
<p>-Gilma Silva Correa, Co-Fundadora</p>
                         
                                
                                </div>
                          </div>
                        
                    </div>    
                    <button onClick={()=>document.location.href='/sobrenos'} className="button1">Quero Saber Mais</button>
              </div>
              <div className="right">
                     
                    <div className="picturewrapper">
                    <div className="gilmapicture"></div>
                    <div className="floterboxbehindpicture"></div>
                    </div>
                   

       </div>

</div>       
</Carousel.Item>

</Carousel> 

          </div>
       

          <div className="cadaprofissional dftsection">  

          <div className="titlesectionholder2">

            <h1>Conectamos Diretamente</h1> 
      
            </div>    
                <div className="leftright">
                <div className="left">
                              <div className="textcontent">
                                    <h2>Buscamos o perfil profissional que você precisa</h2>                                   
                                    <p>1-	Conectamos você aos melhores profissionais</p>
                                    <p>2-	Nenhum investimento realizado até contratação de candidatos</p>
                                   
                                    <a href="/contato"><button className="button2">Quero me conectar</button></a>
                              </div>    
                        </div>
                        <div className="right">
                        <HelpGrow />
                  
                 </div>
          </div>
          </div>

 <div className="somos3itens dftsection">  

<div className="titlesectionholder2">

  <h1>Nosso Serviço é...</h1> 

  </div>    
      <div className="leftright3">
      <div className="left">
                    <div className="textcontent">
                          <h2>Tudo que Sua Instituição Sempre Quis</h2>
                          <p>Realizamos Buscas Assertivas e Abrangentes no Mercado para Trazer o Melhor Talento para a Posição Requisitada.</p>                         
                         
                          <a href="/contato"><button className="button2">Conectar com a QuemIndiq!</button></a>
                    </div>    
              </div>
              <div className="right">
              <div className="threecolumns">
                    <div className="column pt-4">
                          <div className="customizadoicon"></div>
                          <h1>Customizado</h1>
                    </div>
                    
                    <div className="column">
                          <div className="exclusivoicon"></div>
                          <h1>Exclusivo</h1>
                    </div>
                    <div className="column">
                          <div className="eticoicon"></div>
                          <h1>Ético</h1>
                    </div>
                    
              </div>
        
       </div>
</div>
</div>




          <div className="NossoServico dftsection">
          <div className="titlesectionholder2">

<h1>Nosso Diferencial</h1> 

</div>    

                <div className="leftright">
                <div className="left">
                              <div className="textcontent">
                                    <h2>Direto ao Ponto. Sempre.</h2>
                                    <p>1-	Foco no segmento educacional<br></br>
2-	Avaliaçoes técnicas & comportamentais<br></br>
3-	Entrevistas bilingues<br></br>
4-	Seleção ágil para “fit” ideal<br></br>
</p>
                                   
                                    <a href="/sobrenos"><button className="button2">Quero Saber Mais</button></a>
                              </div>    
                        </div>
                        <div className="right">
                        <ExtraLottie5 />
                   
                 </div>
          </div>
          </div>



          <div className="ConectamosDiretamente dftsection">
          <div className="titlesectionholder2">

<h1>Sob Medida & Sob Demanda</h1> 

</div>    
                <div className="leftright">
                <div className="left">
                              <div className="textcontent">
                                    <h2>Requisite Hoje seu Profissional!</h2>
                                    <p>Conectamos a comunidade educacional proporcionando visibilidade e valorização dos profissionais  e excelência na atração e seleção de talentos para as Instituições do segmento de Educaçãona atração e seleção de talentos para qualquer posição acadêmica ou administrativa dentro da Instituição.</p>
                                   
                                    <a href="/contato"><button className="button2">Quero Saber Mais</button></a>
                              </div>    
                        </div>
                        <div className="right">
                            <ExtraLottie4 />

                 </div>
          </div>
          </div>



 
          <div className="FrequentlyAskedQuestions dftsection">
          <div className="titlesectionholder1">

<h1>Se Você ainda Tem Dúvidas...</h1> 

</div>    

          <div className="z z1"></div>
          <div className="z z2"></div>
          <div className="z z3"></div>

          <div className="updown">
                  <div className="up">
                  <div className="leftright">
                               
                               <div className="left">
                                     <div className="textcontent">
                                           <h2>Nos Pergunte e Responderemos!</h2>
                                           <p>Dúvidas?  Estamos aqui para esclarecê-las!</p>
                                           <p>Apoiamos todos os profissionais e as Instituições da área de Educação na definição de estruturas , movimentação de carreiras e detalhamento de perfis para posições em aberto.</p>
                                          
                                           <a href="/contato"><button className="button1">Quero Perguntar!</button></a>
                                     </div>    
                               </div>
                               <div className="right">
                                 <ExtraLottie3 />
                                 
                
                               </div>
                 </div>                        
                  </div>
                  <div className="down">
                        <div className="questionwrapper">
                                    
        <Accordion defaultActiveKey="0">
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
          1- Que serviços a QuemIndiq pode prestar para minha instituição?  
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>A QuemIndiq visa prover soluções de qualidade na área de recrutamento e seleção de talentos com foco exclusivo no segmento da Educação. Uma instituição educacional – seja ela escola, curso livre, editora de livros didáticos, escola bilíngue, escola internacional entre outros – tem resultados de excelência na mesma proporção dos profissionais que integram sua equipe e entregam seu propósito.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
          2- Por que utilizar consultoria especializada para seleção de pessoal?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>O processo de seleção de talentos não se resume a busca de CVs dentro de sua rede de contatos. Cada instituição tem sua cultura, sua visão, sua missão e precisa buscar, de forma ampla no mercado, os melhores perfis para suas vagas, onde quer que estejam! Isto inclui não somente qualificações e histórico profissional mas também as aspirações de cada profissional e seu perfil comportamental. Nosso propósito é dar o suporte neste processo – chave ampliando suas oportunidades de contratar esses talentos.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
          3- Como devo proceder quando precisar de  profissionais?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>Você define como prefere que o processo seja realizado. Entre em contato por email        contato@quemindiq.com.br  ou telefone (21-99597-9655 /21-99991-5595) e marcamos  uma reunião virtual e estaremos com você para definir o perfil que necessita, suas peculiaridades e necessidades .</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
          4- Qual é o valor do Investimento?
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>O valor do investimento está baseado na necessidade específica de cada projeto.  Formas de pagamento podem ser definidas pelo cliente de acordo com seus procedimentos e necessidades  internas.Trabalhamos com “Success Fee” – Você só paga depois de escolher o candidato selecionado pela QUEMINDIQ. </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
          5- E a confidencialidade dos processos seletivos? 
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>Temos o compromisso de realizarmos as buscas com sigilo. Somente os candidatos finalistas selecionados saberão o nome da instituição quando forem para as  entrevistas. </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <a href="/contato"><button className="button1">Tenho Outras Dúvidas!</button></a>
                  
      </div>
                  </div>



          </div>

        
     
          </div>
          <ThatPixFooter />   
    </div>
  );
}

export default Institutionhomecontent;
