import React from 'react'

export default class SobrePessoaComponent extends React.Component{
    render(){
        return(
            <div style={{height: '100%'}} className="sobre-pessoa-component-w w-full bg-red-600">
                    <div className="absolute-w-full-w absolute top-0 w-full flex items-center justify-center -mt-16">
                            <div className="picture w-32 h-32 rounded-full">
                                <img src={this.props.imagePessoa} alt="" />
                            </div>
                          </div>     

                          <div className="text-part-w p-4 flex flex-col items-stretch justify-stretch">
                            
                                    <p className="mt-12 w-full text-4xl font-bold font-sans text-center text-gray-200">{this.props.nomePessoa}</p>
                                  <p className="text-lg w-full text-center text-gray-100">{this.props.tituloPessoa}</p>
                                  <p className=" text-center text-gray-300">{this.props.textoPessoa}</p> 

                    </div>          
            </div>
            
       )
    }

}