import React, { Component } from 'react';
import Lottie from 'react-lottie';
import LockedLottieData from '../../lottie/13150-locked.json';

class LockedLottie2 extends Component {


  render(){

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LockedLottieData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div>        
        <Lottie options={defaultOptions}
              height={400}
              width={400}
        />
      </div>
    )
  }
}

export default LockedLottie2