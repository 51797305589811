import React from 'react'
import Dashboard from '../components/SelfPerfilFrontEnd/DashboardRelated/Dashboard'

export default function DashboardPage() {
  return (
    <div className={`bg-gray-800 flex items-center justify-start flex-col w-screen min-h-screen h-full`}>
        <Dashboard/>
    </div>
  )
}
