import '../../css/defaultherosection.css';

import React, { useRef, useState } from 'react';
import swal from 'sweetalert';
import emailjs from 'emailjs-com';
import { init } from 'emailjs-com';


export default function ContactForm3(props) {

    //init emailjs
    init("user_AQFigMcZFSeIwe13u8KAw");

    const formData = useRef({
        name: '', 
        subject: 'Outros',
        email: '', 
        whatsapp: '',
        message: '',
    })

const [formButtonData, setformButtonData] = useState({
    text: 'Enviar',
})

    const handleInputData = (dataTitle, e) => {
        formData.current[dataTitle] = e.target.value
    }

    const handleSendEmail = (e) => {
        e.preventDefault()

        
    if (formData.current.name === '' ||formData.current.whatsapp === '' || formData.current.email === '' || formData.current.message === '') {
        swal({
            title: "Alerta de Campo Vazio!",
            icon: "warning",
            text: "Todos os campos devem estar devidamente preenchidos para o envio deste formulário! Preencha os campos vazios e tente novamente!"
         })
    }
    else{
         if (!formData.current.email.includes('@') || !formData.current.email.includes('.')) {
            swal({
                title: "Alerta de E-mail Invalido!",
                icon: "warning",
                text: "Por Favor escreva um e-mail valido para continuar!"
             })
         } else {           
        
        if (formButtonData.text==="Processando...") {            
            swal({
               title: "Calma!",
               icon: "warning",
               text: "Sua Mensagem Esta sendo Processada. Por favor, aguarde o termino do envio para reutilizar este formulário."
            })
        } 
        else{

            if (/[a-zA-Z]/.test(formData.current.whatsapp)) {

                swal({
                    title: "Digitos Invalidos",
                    icon: "warning",
                    text: "Por favor, utilize apenas números e caracteres especiais no campo WhatsApp."
                 })

                
            }
             else {  

                setformButtonData({text:'Processando...'})

                emailjs.send("service_4r7davr","template_00wi71u",{
                    subject: formData.current['subject'],
                    name: formData.current['name'],
                    email: formData.current['email'],
                    whatsapp: formData.current['whatsapp'],
                    message: formData.current['message'],
                    }).then(
                            message => {              
                                console.log(message);        
                                if (message.status===200){                
                                      swal({
                                          icon: "success",
                                          text:"Sua Mensagem foi Enviada com Sucesso!"})   
                                          
                                          var inputs = document.querySelectorAll('input')
                                          for (let index = 0; index < inputs.length; index++) {
                                             inputs[index].value = '';
                                          }
                                          var textareas = document.querySelectorAll('textarea')
                                              for (let index = 0; index < textareas.length; index++) {
                                              textareas[index].value = '';
                                           }
          
                                }
                                else{
                                      swal("Houve Algum Erro Enviando sua Mensagem. Por favor Tente Novamente Mais Tarde.") 
                                }
                                setformButtonData({text:'Enviar'})
                            })
                                 
        }}}}

    }
 
  return (
   
      <div className="ContactForm3-w pt-16">

<h1 className="mb-8 font-sans">Sobre o que deseja falar?</h1>
<p> Preencha os campos abaixo que direcionaremos sua mensagem para o setor correto! Podemos conversar pessoalmente, por vídeo, por telefone ou diretamente através da plataforma – o que melhor lhe atender.</p>
  
               <form id="ContactForm3" >
  
  <div className="form-group">
      <label htmlFor="nome">Nome</label>        
      <input onChange={(e) => {handleInputData(`name`,e)}} type="text" className="form-control" name="nome" />
  </div>

  <div className="form-group">
      <label htmlFor="exampleInputEmail1">Email address</label>
      <input onChange={(e) => {handleInputData(`email`,e)}} type="email" className="form-control" aria-describedby="emailHelp"  name="email" />
  </div>

  <div className="form-group">
      <label htmlFor="WhatsApp">WhatsApp</label>        
      <input onChange={(e) => {handleInputData(`whatsapp`,e)}} type="text" className="form-control" name="whatsapp" />
  </div>

  <div className="form-group">
      <label htmlFor="message">Message</label>
      <textarea onChange={(e) => {handleInputData(`message`,e)}} className="form-control" rows="5"  name="message"></textarea>
  </div>

  <button onClick={(e) => {
      handleSendEmail(e)
  }} type="submit" className="btn btn-primary">{formButtonData.text}</button>

</form>

      </div>
  )

}
