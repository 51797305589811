import React, { useEffect, useState, useRef } from 'react'
import { useSelfPerfilApiContext } from '../../../contexts/SelfPerfilAPIContext'
import {useLocation} from 'react-router-dom'
import SelfPerfilProfile from '../SelfPerfilProfile'

// UI resources
const HighlightedParagraphWithIcon = ({icon, children}) => {
    return (
        <div className={`flex border-l-2 pl-4 border-blue-500 items-center flex-grow justify-center gap-4`}>
            <div className={`flex items-center justify-center`}>
                {icon}
            </div>
        <span className={`font-base`}>
            {children}
        </span>    
        </div>
    )
}

const HighlightQualityTag = ({children}) => {
    return(
        <div className={`font-light text-lg flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-lg`}>
            <span>{children}</span>
        </div>
    )
}


// End of UI resources
const Profile1 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Demandante'},
        {id:2,text:'Objetivo'},
        {id:3,text:'Determinado'},
        {id:4,text:'Obstinado'},
        {id:5,text:'Pragmático'},
        {id:6,text:'Decidido'},
        {id:7,text:'Destemido'},
        {id:7,text:'Dotado de garra'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-center justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Você demonstra ser um profissional bastante direto, visivelmente dotado de
        “garra”, ama desafios e se sente bem com eles! Extremamente determinado,
        obstinado e exigente, tem um excelente foco nas ações e nos resultados que
        deseja alcançar. Você tem objetivos claros e trabalha direcionado para eles.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Sua agilidade é notável! Rápido na execução, demonstra gostar de fazer várias
        coisas ao mesmo tempo, sem se abater frente a obstáculos - busca soluções
        sem medo! Destemido e Decidido – são palavras que podem refletir bem sua
        tendência comportamental!
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Demonstra muita determinação, poder de decisão, sempre com atitude
        positiva de fazer acontecer e com foco em resolver problemas com muita
        criatividade!
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
            *De acordo com o que você entende ser suas menores preferências, seu perfil natural
            tende a ser mais formal, reflexivo e racional.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
            DETERMINAÇÃO é seu lema. E ela fica ainda mais visível, pois pessoas com o seu
            perfil preferem não focar muito em assuntos pessoais. Costuma ser direto e assertivo,
            possui uma visão objetiva e analítica, tendendo a perseguir seus objetivos
            incansavelmente. Aprecia ver resultados no seu trabalho e tem foco em alcançá-los.

        </p> 
                    
     <p>
         
               Todos nós devemos cuidar das percepções junto aos demais. Por isso, para suporte em
                seu desenvolvimento e crescimento contínuo, seguem algumas dicas, que foram
                ressaltadas através de sua auto avaliação e que poderão ajudar ainda mais no seu
                sucesso profissional!
     </p>
  
   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
            Por conta de sua rapidez e agilidade nas atitudes, sempre cuide para que os
            mais introvertidos não o percebam como arrogante- fato que você certamente
            não é!
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           O forte foco nos resultados pode, de alguma forma, dar a errônea impressão de  grande ansiedade - nada que uma boa e cuidadosa comunicação não consiga
        resolver.
        </HighlightedParagraphWithIcon>  


        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Seu foco nos resultados, junto com lógica e racionalidade podem, em algum
            momento, fazer você ser percebido como autoritário ou controlador. Mais uma
            vez, cuidar da forma de como você se comunica com seus pares e demais
            membros da equipe, vai ajudá-lo!
        </HighlightedParagraphWithIcon>  
     

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
              Seu forte entusiasmo, poderá ser visto como alguém que pressiona demais –
            afinal, resultados são importantes! Vc gosta de refletir e racionalizar – use isso
            a seu favor nestas situações.
        </HighlightedParagraphWithIcon>  
     
        <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>

        <div className={`grid   lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={`   h-16 text-center e p-4 flex items-center justify-center`}>Auto Confiança</div>
                <div className={`text-center p-4 `}>Flexibilidade</div>
              <div className={`text-center p-4 bg-red-500 text-white `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4`}>Padrão de Trabalho</div>
                <div className={`text-center p-4  `}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4   `}>Foco em Detalhe</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Liderança</div>
                <div className={`text-center p-4  `}>Pensamento Analítico</div>
                <div className={`text-center p-4 `}>Positividade</div>
                <div className={`text-center p-4 bg-red-500 text-white  `}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16   text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center bg-red-500 text-white p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4   `}>Negociação</div>
                <div className={`text-center bg-red-500 text-white  p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4  `}>Relacionamento Interpessoal</div>
                <div className={`text-center bg-red-500 text-white p-4  `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4  `}>Gerenciamento de Processos</div>
                <div className={`text-center p-4 `}>Networking</div>
                <div className={`text-center p-4  `}>Peseverança</div>
                <div className={`text-center p-4 `}>Resolução de Conflitos</div>
                <div className={`text-center p-4  `}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`bg-red-500 text-white h-16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 `}>Gestão do Tempo</div>
                <div className={`text-center p-4  `}>Paciência</div>
                <div className={`text-center p-4 `}>Persuasão</div>
                <div className={`text-center p-4  `}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}

const Profile2 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Vigoroso'},
        {id:2,text:'Objetivo'},
        {id:3,text:'Determinado'},
        {id:4,text:'Orientado a Resultados'},
        {id:5,text:'Ativo'},
        {id:6,text:'Auto Motivado'},
        {id:7,text:'Muita Energia'},
        {id:8,text:'Senso de Urgência'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-start justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
Você demonstra ser um profissional muito ativo, com muita iniciativa e
extremamente orientado para os resultados – isso o motiva e mostra um
profissional vigoroso! Você tende a demonstrar muita energia que, aliado ao
seu senso de urgência, em muito colabora com o ambiente à sua volta e com
os resultados esperados. Sua flexibilidade e gosto pela diversidade
demonstram que assume riscos sem medo.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
Inquieto e bem direcionado! Palavras que refletem sua tendência
comportamental!
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
     Você também demonstra muita determinação, sempre com atitude positiva de
fazer acontecer, enfrenta problemas com muita criatividade!
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
        *De acordo com o que você entende ser suas menores preferências, seu perfil natural tende a
ser mais impetuoso.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
        Sua Automotivação é facilmente percebida pois ação é sua maior preferência!
Gosta de estar ativo o tempo todo, tendendo até a se mostrar um tanto impaciente
com aqueles que preferem não acompanhar seu ritmo. Reage rapidamente a novos
acontecimentos, e nunca deixa de perseguir suas metas.

        </p> 
                    
  
 <p>
               Todos nós devemos cuidar das percepções junto aos demais. Por isso, para suporte em
                seu desenvolvimento e crescimento contínuo, seguem algumas dicas, que foram
                ressaltadas através de sua auto avaliação e que poderão ajudar ainda mais no seu
                sucesso profissional!
 </p>

   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
          Por conta de sua rapidez e agilidade nas atitudes e foco nos resultados, sempre
cuide para que as pessoas não leiam isso como impetuosidade ou inquietude
demais - cuide desta percepção !
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
          O forte foco nos resultados pode, de alguma forma, dar a errônea impressão de
grande ansiedade ou mesmo competitividade - nada que uma boa e cuidadosa
comunicação não consiga resolver.
        </HighlightedParagraphWithIcon>  


        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
          Seu foco nos resultados, junto com lógica e racionalidade podem, em algum
momento, fazer você ser percebido como autoritário ou controlador. Mais uma
vez, cuidar da forma de como você se comunica com seus pares e demais
membros da equipe, vai ajudá-lo!
        </HighlightedParagraphWithIcon>  
     

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
            Sua característica de assumir riscos com muita energia pode ser percebido, de
novo, como competitivo demais ou alguém que pressiona demais – afinal,
resultados são importantes! Cuide aqui também.
        </HighlightedParagraphWithIcon>  
     
        <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>

        <div className={`grid   lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={`   h-16 text-center e p-4 flex items-center justify-center`}>Auto Confiança</div>
                <div className={`text-center p-4 `}>Flexibilidade</div>
              <div className={`text-center p-4 bg-red-500 text-white `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4`}>Padrão de Trabalho</div>
                <div className={`text-center p-4  `}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4   `}>Foco em Detalhe</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Liderança</div>
                <div className={`text-center p-4  `}>Pensamento Analítico</div>
                <div className={`text-center p-4 `}>Positividade</div>
                <div className={`text-center p-4 bg-red-500 text-white  `}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16   text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center bg-red-500 text-white p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4   `}>Negociação</div>
                <div className={`text-center bg-red-500 text-white  p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4  `}>Relacionamento Interpessoal</div>
                <div className={`text-center bg-red-500 text-white p-4  `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4  `}>Gerenciamento de Processos</div>
                <div className={`text-center p-4 `}>Networking</div>
                <div className={`text-center p-4  `}>Peseverança</div>
                <div className={`text-center p-4 `}>Resolução de Conflitos</div>
                <div className={`text-center p-4  `}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`bg-red-500 text-white h-16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 `}>Gestão do Tempo</div>
                <div className={`text-center p-4  `}>Paciência</div>
                <div className={`text-center p-4 `}>Persuasão</div>
                <div className={`text-center p-4  `}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}
const Profile3 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Firme'},
        {id:2,text:'Persistente'},
        {id:3,text:'Pioneiro'},
        {id:4,text:'Audacioso'},
        {id:5,text:'Independente'},
        {id:6,text:'Espirito de Aventura'},
        {id:7,text:'Empreendedor'},
        {id:7,text:'Assume Riscos'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-start justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
       Você demonstra ser um profissional muito ativo e audacioso! Firme e bastante
inquisitivo, é focado nos resultados com incrível persistência! Demonstra
autossuficiência e capacidade de assumir riscos e forte espírito de aventura.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
Pioneiro, demonstra independência e impulsividade em seu foco nos
resultados a serem atingidos.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
      Perfil com alta autoestima e muita determinação – palavras que refletem sua
tendência comportamental !
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
  Você demonstra ser empreendedor por natureza ! Gosta de iniciar projetos, e
aproveitar as oportunidades.
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
        *De acordo com o que você entende ser suas menores preferências, tende a se revelar mais
irreverente e altivo na busca por seu bom desempenho.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
        Independência é o seu lema! Pode até se sentir um pouco frustrado se for
submetido a regras, procedimentos e regulamentos em demasia. Prefere seguir suas
ideias, gosta de empreender !

        </p> 
                    

  <p>
               Todos nós devemos cuidar das percepções junto aos demais. Por isso, para suporte em
                seu desenvolvimento e crescimento contínuo, seguem algumas dicas, que foram
                ressaltadas através de sua auto avaliação e que poderão ajudar ainda mais no seu
                sucesso profissional!
  </p>
 
   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
         Por conta de sua autossuficiência, independência e agilidade nas atitudes e
foco nos resultados, sempre cuide para que as pessoas não leiam isso como
altivez e arrogância - cuide desta percepção ouvindo mais e se comunicando
melhor!
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
         Sua tendência à alta autoestima e certa dose de irreverencia podem, de alguma
forma, dar a errônea impressão de impulsividade - nada que uma boa e
cuidadosa comunicação não consiga resolver..
        </HighlightedParagraphWithIcon>  


        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
       Seu foco nos resultados , junto com espirito aventureiro e audacioso podem,
em algum momento, fazer você ser percebido como autoritário ou controlador.
Mais uma vez, cuidar da forma de como você se comunica com seus pares e
demais membros da equipe , vai ajudá-lo!
        </HighlightedParagraphWithIcon>  
     

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
       Sua característica de assumir riscos com muita energia e persistência pode ser
percebido, de novo, como competitivo ou alguém que pressiona demais –
afinal, resultados são importantes! Cuide disso aqui também.
        </HighlightedParagraphWithIcon>  
     
        <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>

        <div className={`grid   lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={`   h-16 text-center e p-4 flex items-center justify-center`}>Auto Confiança</div>
                <div className={`text-center p-4 `}>Flexibilidade</div>
              <div className={`text-center p-4 bg-red-500 text-white `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4`}>Padrão de Trabalho</div>
                <div className={`text-center p-4  `}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4   `}>Foco em Detalhe</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Liderança</div>
                <div className={`text-center p-4  `}>Pensamento Analítico</div>
                <div className={`text-center p-4 `}>Positividade</div>
                <div className={`text-center p-4 bg-red-500 text-white  `}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16   text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center bg-red-500 text-white p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4   `}>Negociação</div>
                <div className={`text-center bg-red-500 text-white  p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4  `}>Relacionamento Interpessoal</div>
                <div className={`text-center bg-red-500 text-white p-4  `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4  `}>Gerenciamento de Processos</div>
                <div className={`text-center p-4 `}>Networking</div>
                <div className={`text-center p-4  `}>Peseverança</div>
                <div className={`text-center p-4 `}>Resolução de Conflitos</div>
                <div className={`text-center p-4  `}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`bg-red-500 text-white h-16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 `}>Gestão do Tempo</div>
                <div className={`text-center p-4  `}>Paciência</div>
                <div className={`text-center p-4 `}>Persuasão</div>
                <div className={`text-center p-4  `}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}
const Profile4 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Sociável'},
        {id:2,text:'Empatico'},
        {id:3,text:'Extrovertido'},
        {id:4,text:'Comunicativo'},
        {id:5,text:'Participativo'},
        {id:6,text:'Expansivo'},
        {id:7,text:'Orientado para Pessoas'},
        {id:7,text:'Diplomático'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-start justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Você demonstra ser extremamente orientado para as pessoas , muito
empático e agregador, com um astral incrível. Isso o permite contornar
dificuldades com maestria! Em função disso, constrói um network sólido que dá
suporte ao alcance de seus objetivos.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
   Usualmente extrovertido e expansivo, sabe equilibrar suas preferências de
interação com moderação, relacionando-se e interagindo com facilidade,
promovendo sinergia com suas equipes.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
    Por demonstrar ser comunicativo, diplomático e cuidadoso, as pessoas tendem
a gostar de trabalhar com você, principalmente por demonstrar ser
extremamente interessado em gente.
        </HighlightedParagraphWithIcon>   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
Em geral gosta de ser participativo, gerar ideias e dividir com os demais!
Despretensioso, demonstra otimismo também com relação ao potencial
daqueles com quem trabalha. Criando assim um ambiente muito motivador no
trabalho.
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
        *De acordo com o que você entende ser suas menores preferências, se revela
mais moderado com relação a metas em algumas circunstâncias.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
        SOCIABILIDADE parece a primeira impressão que você causa! Demonstra ser
muito amigável e adora conversar. Comunicação parece ser seu elemento mais forte.
Em geral é muito extrovertido.

        </p> 
                    

      <p>
               Todos nós devemos cuidar das percepções junto aos demais. Por isso, para suporte em
                seu desenvolvimento e crescimento contínuo, seguem algumas dicas, que foram
                ressaltadas através de sua auto avaliação e que poderão ajudar ainda mais no seu
                sucesso profissional!
      </p>
       
   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Por gostar muito de falar com tendência a ser expansivo pode ser percebido
como não tão bom ouvinte ou que dá pouco espaço para o outro. Na
interação, portanto, cuide de dar espaço aos mais introvertidos para que se
coloquem com mais facilidade.
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Por enxergar o todo de forma entusiasta, muito voltado para as pessoas e sua
comunicação com elas, pode ser percebido como não atento a detalhes ou
diplomático demais. Seu senso de equipe ajuda muito nestes casos, onde
pode buscar suporte no caso de perceber este tipo de avaliação.
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
Pode haver uma percepção de um comportamento apressado e impulsivo, use
sua moderação e empatia para que todos os demais entendam seu estilo .
        </HighlightedParagraphWithIcon>  


        
     
        <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>

        <div className={`grid lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={` h-16 text-center bg-red-500 text-white p-4 flex items-center justify-center`}>Autoconfiança</div>
                <div className={`text-center p-4 `}>Flexibilidade</div>
              <div className={`text-center p-4   `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4`}>Padrão de Trabalho</div>
                <div className={`text-center p-4  `}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center bg-red-500 text-white  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4   `}>Foco em Detalhe</div>
                <div className={`text-center p-4  `}>Liderança</div>
                <div className={`text-center p-4  `}>Pensamento Analítico</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Positividade</div>
                <div className={`text-center p-4   `}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 bg-red-500 text-white text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center   p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4   `}>Negociação</div>
                <div className={`text-center    p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Relacionamento Interpessoal</div>
                <div className={`text-center  p-4  `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4  `}>Gerenciamento de Processos</div>
                <div className={`text-center p-4 `}>Networking</div>
                <div className={`text-center p-4  `}>Peseverança</div>
                <div className={`text-center p-4 `}>Resolução de Conflitos</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`  h-16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 `}>Gestão do Tempo</div>
                <div className={`text-center p-4  `}>Paciência</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Persuasão</div>
                <div className={`text-center p-4  `}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}
const Profile5 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Otimista'},
        {id:2,text:'Inspirador'},
        {id:3,text:'Positivo'},
        {id:4,text:'Persuasivo'},
        {id:5,text:'Ativo'},
        {id:6,text:'Muita Energia'},
        {id:7,text:'Entusiasta'},
        {id:7,text:'Flexível'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-center justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
Demonstra ser otimista por natureza, positivo, com um alto astral que permite
contornar dificuldades com maestria! Em função disso, tende a ser muito
convincente e inspirador em suas equipes.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
       Usualmente ativo, expansivo e inquieto, demonstra muita energia e alto poder
de persuasão. Tem senso de urgência nas suas realizações , aprecia diversidade
no trabalho e relaciona-se com facilidade, promovendo sinergia com suas
equipes.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Por demonstrar ser comunicativo e enfático, as pessoas tendem a gostar de
trabalhar com você, pois é percebido como flexível e extremamente
interessado em gente.
        </HighlightedParagraphWithIcon>    
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Demonstra otimismo não somente com relação ao alcance dos resultados
como também ao potencial daqueles com quem trabalha. Com isso cria um
ambiente muito motivador no trabalho.
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
        *De acordo com o que você entende ser suas menores preferências tende a
se revelar um tanto impetuoso em algumas circunstancias.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
        Entusiasmo é seu lema ! Demonstra ser descontraído e extrovertido e , além de
muito sociável, tende a ter muita energia. Este estilo animado e efusivo é muito
motivador para os que estão a seu redor. Demonstra fácil e visivelmente quando o
assunto lhe é interessante !
        </p> 
                    
    
         <p>
               Todos nós devemos cuidar das percepções junto aos demais. Por isso, para suporte em
                seu desenvolvimento e crescimento contínuo, seguem algumas dicas, que foram
                ressaltadas através de sua auto avaliação e que poderão ajudar ainda mais no seu
                sucesso profissional!
      
         </p>
   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
            Por tender a ser muito persuasivo e convincente , pode ser percebido como
um tanto inquieto e urgente demais, dando pouco espaço para o outro. Na
interação, portanto, cuide de dar espaço aos mais introvertidos para que se
coloquem com mais facilidade.
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Por enxergar o todo com muita energia e de forma positiva e otimista, pode
ser percebido como impetuoso, não atento a detalhes. Seu senso de equipe e
pertencimento ajudam muito nestes casos.
        </HighlightedParagraphWithIcon>  


        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Pode haver uma percepção de um comportamento um tanto enérgico ou
enfático demais. Você inspira - use sua empatia , para que todos os demais
entendam seu estilo.
        </HighlightedParagraphWithIcon>  
         
        <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>

        <div className={`grid   lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={` h-16 text-center bg-red-500 text-white p-4 flex items-center justify-center`}>Auto Confiança</div>
                <div className={`text-center p-4 `}>Flexibilidade</div>
              <div className={`text-center p-4   `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4`}>Padrão de Trabalho</div>
                <div className={`text-center p-4  `}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center bg-red-500 text-white  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4   `}>Foco em Detalhe</div>
                <div className={`text-center p-4`}>Liderança</div>
                <div className={`text-center p-4  `}>Pensamento Analítico</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Positividade</div>
                <div className={`text-center p-4`}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 bg-red-500 text-white text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4   `}>Negociação</div>
                <div className={`text-center  p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Relacionamento Interpessoal</div>
                <div className={`text-center  p-4  `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4  `}>Gerenciamento de Processos</div>
                <div className={`text-center p-4 `}>Networking</div>
                <div className={`text-center p-4  `}>Peseverança</div>
                <div className={`text-center p-4 `}>Resolução de Conflitos</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`  h-16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 `}>Gestão do Tempo</div>
                <div className={`text-center p-4  `}>Paciência</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Persuasão</div>
                <div className={`text-center p-4  `}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}
const Profile6 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Rápido'},
        {id:2,text:'Aberto'},
        {id:3,text:'Positivo'},
        {id:4,text:'Firme'},
        {id:5,text:'Persistente'},
        {id:6,text:'Sociável'},
        {id:7,text:'Espírito de aventura'},
        {id:7,text:'Autoconfiante'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-center justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
      Você demonstra ser um profissional aberto, empático, que inicia relações com
muita facilidade. Sociável, com espírito de aventura, busca resultados com
firmeza e confiança .
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
       Usualmente rápido, autoconfiante e audacioso em seu foco, demonstra
segurança , auto percepção e consequente sólido poder de persuasão. Flexível
e persistente, demonstra capacidade de assumir riscos na busca pelo
atingimento dos resultados em que acredita.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Por demonstrar ser entusiasta, independente e ao mesmo tempo orientado
para pessoas , tendem a se sentir motivados a trabalhar com você.
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
        *De acordo com o que você entende ser suas menores preferências , tende a
ser percebido como impulsivo em algumas circunstâncias.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
        AUTOCONFIANÇA parece ser a primeira impressão que você causa aos que estão
a seu redor . Não tende a demonstrar dúvidas sobre como agir e parece estar sempre
muito à vontade em situações sociais. Lida facilmente com estranhos e não têm medo
de iniciar contatos sociais.

        </p> 
                    
    
         <p>
               Todos nós devemos cuidar das percepções junto aos demais. Por isso, para suporte em
                seu desenvolvimento e crescimento contínuo, seguem algumas dicas, que foram
                ressaltadas através de sua auto avaliação e que poderão ajudar ainda mais no seu
                sucesso profissional!
           
         </p>
   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
            Por tender a ser muito independente e autoconfiante , pode ser percebido
como um tanto urgente demais, dando pouco espaço para o outro. Na
interação, portanto, cuide de dar espaço aos mais introvertidos para que se
coloquem com mais facilidade.
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Percebido como audacioso e com capacidade de assumir riscos com
facilidade, pode ser percebido como impetuoso ou aventureiro , não atento a
detalhes. Seu senso de equipe e pertencimento ajudam muito nestes casos.
        </HighlightedParagraphWithIcon>  


        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Por ser usualmente firme e rápido , pode ser visto como um tanto enérgico
ou enfático demais. Use sua empatia e facilidade de relacionamento para que
todos os demais entendam seu estilo.
        </HighlightedParagraphWithIcon>  
       
     
        <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>

        <div className={`grid   lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={` h-16 text-center bg-red-500 text-white p-4 flex items-center justify-center`}>Auto Confiança</div>
                <div className={`text-center p-4 `}>Flexibilidade</div>
              <div className={`text-center p-4   `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4`}>Padrão de Trabalho</div>
                <div className={`text-center p-4  `}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center bg-red-500 text-white  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4   `}>Foco em Detalhe</div>
                <div className={`text-center p-4`}>Liderança</div>
                <div className={`text-center p-4  `}>Pensamento Analítico</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Positividade</div>
                <div className={`text-center p-4`}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 bg-red-500 text-white text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4   `}>Negociação</div>
                <div className={`text-center  p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Relacionamento Interpessoal</div>
                <div className={`text-center  p-4  `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4  `}>Gerenciamento de Processos</div>
                <div className={`text-center p-4 `}>Networking</div>
                <div className={`text-center p-4  `}>Peseverança</div>
                <div className={`text-center p-4 `}>Resolução de Conflitos</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`  h-16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 `}>Gestão do Tempo</div>
                <div className={`text-center p-4  `}>Paciência</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Persuasão</div>
                <div className={`text-center p-4  `}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}
const Profile7 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Sereno'},
        {id:2,text:'Amável'},
        {id:3,text:'Cuidadoso'},
        {id:4,text:'Diplomático'},
        {id:5,text:'Bom ouvinte'},
        {id:6,text:'Receptivo'},
        {id:7,text:'Ponderado'},
        {id:7,text:'Autocontrolado'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-center justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
  Seu perfil naturalmente inspira calma e serenidade ao seu redor, uma pessoa
estável e um ouvinte muito atento. Pessoas com esse perfil são percebidos
como equilibradas, diplomáticas e excelentes apaziguadores de conflitos -
usualmente oferecem valioso suporte em momentos de tensão e crise.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
      Tende a ser cuidadoso, auto controlado e busca segurança, usando tempo e
cautela para tomar decisões, avaliando cuidadosamente as alternativas e as
opiniões que emite.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Despretensioso, é percebido como fiel, muito amável e é reconhecido como
muito receptivo e prestativo, com vontade de ajudar os outros. Tende a se
mostrar introvertido e em geral é um grande colaborador para a criação de
um ambiente de trabalho de harmonia e estabilidade.
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
        *De acordo com o que você entende ser suas menores preferências, tende a
ser percebido como conservador e mais reservado.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
        Paciência é seu lema! Gosta de manter boa organização e evita ritmo de urgência
para garantir ponderação e segurança dos trabalhos. Gosta de ser cauteloso e
claramente trabalha muito bem e com foco mesmo que a tarefa pareça ser repetitiva e
nem tão atraente.
        </p> 
                    
      
        <p>
               Todos nós devemos cuidar das percepções junto aos demais. Por isso, para suporte em
                seu desenvolvimento e crescimento contínuo, seguem algumas dicas, que foram
                ressaltadas através de sua auto avaliação e que poderão ajudar ainda mais no seu
                sucesso profissional!
       
        </p>
   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
            Geralmente, por ser cuidadoso e conservador, tem preferência por executar
uma tarefa de cada vez e por isso pode ser percebido como mais lento no
processo de tomada de decisão. Comunicar seu estilo a seus pares poderá
ajudá-lo a tratar esta percepção.
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Por conta da tendência à introversão, é importante que sempre cuide de
garantir sua participação e opinião em reuniões e treinamentos – isso ajudará
também a não haver percepção de falta de entusiasmo.
        </HighlightedParagraphWithIcon>  


        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
          Cuide sempre de não tomar as dificuldades pelo lado pessoal. Temos estilos
distintos e compartilhar isso ajuda na comunicação de todos!
        </HighlightedParagraphWithIcon>  
        
        <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>

        <div className={`grid lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={`   h-16 text-center p-4 flex items-center justify-center`}>Auto Confiança</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Flexibilidade</div>
              <div className={`text-center p-4  `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4`}>Padrão de Trabalho</div>
                <div className={`text-center p-4  `}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4   `}>Foco em Detalhe</div>
                <div className={`text-center p-4  `}>Liderança</div>
                <div className={`text-center p-4  `}>Pensamento Analítico</div>
                <div className={`text-center p-4 `}>Positividade</div>
                <div className={`text-center p-4  `}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16   text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Negociação</div>
                <div className={`text-center p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4  `}>Relacionamento Interpessoal</div>
                <div className={`text-center p-4 `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center bg-red-500 text-white  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4  `}>Gerenciamento de Processos</div>
                <div className={`text-center p-4  bg-red-500 text-white`}>Networking</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Peseverança</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Resolução de Conflitos</div>
                <div className={`text-center p-4  `}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`  -16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 `}>Gestão do Tempo</div>
                <div className={`text-center p-4  `}>Paciência</div>
                <div className={`text-center p-4 `}>Persuasão</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}
const Profile8 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Ponderado'},
        {id:2,text:'Organizado'},
        {id:3,text:'Estável'},
        {id:4,text:'Autocontrolado'},
        {id:5,text:'Demonstra bom senso'},
        {id:6,text:'Racional'},
        {id:7,text:'Estruturado'},
        {id:7,text:'Lógico'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-center justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        De acordo com sua auto avaliação, seu perfil é naturalmente muito
estruturado, tendendo a ser formal e mais sério : inspira calma e serenidade ao
seu redor, uma pessoa estável, muito reflexivo e planejado. Pessoas com esse
perfil são percebidos como apaziguadores de conflitos - usualmente oferecem
valioso suporte em momentos de tensão e crise
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
       Tende a ser cuidadoso, auto controlado e bastante ponderado, usando tempo
e cautela para tomar decisões, avaliando cuidadosamente as alternativas e as
opiniões que emite.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Em geral reservado e racional, é percebido como lógico e um valioso
colaborador para a criação de um ambiente de trabalho de harmonia e
estabilidade.!
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
        *De acordo com o que você entende ser suas menores preferências, tende a
ser percebido como um tanto cético.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
        CONSIDERAÇÃO é a característica sua facilmente percebida pelos que estão à sua
volta. Tende a ser muito organizado, gosta de planejar o que diz e as açōes que
executa, sempre com cautela, evitando agir sob impulso. Por toda esta estruturação e
racionalidade, pode ter alguma dificuldade para lidar com prazos e limitações de

tempo. Demonstra estabilidade e exibe qualidade e confiabilidade nas tarefas que
entrega.

        </p> 
                    
    
        <p>
               Todos nós devemos cuidar das percepções junto aos demais. Por isso, para suporte em
                seu desenvolvimento e crescimento contínuo, seguem algumas dicas, que foram
                ressaltadas através de sua auto avaliação e que poderão ajudar ainda mais no seu
                sucesso profissional!
        
        </p>
   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
            Geralmente, por ser reflexivo e ponderado, tem preferência por executar uma
tarefa de cada vez, de forma estruturada, e por isso pode ser percebido como
mais lento no processo de tomada de decisão e ação. Isso também pode afetar
cumprimento de prazos . Estar atento a isso e comunicar seu estilo a seus pares
poderá ajudá-lo a resolver este item.
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Por ter tendência a ser reservado, sério e formal, é importante que sempre
cuide de garantir sua participação e opinião dentro das equipes – isso ajudará
também a não haver percepção de falta de entusiasmo.
        </HighlightedParagraphWithIcon>  


        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           O fato de demonstrar ser lógico, cético e racional pode por vezes ser tomado
como descrente das ações discutidas. Temos estilo distintos e compartilhar isso
ajuda na comunicação de todos!
        </HighlightedParagraphWithIcon>  
     
     
        <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>

        <div className={`grid lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={`   h-16 text-center p-4 flex items-center justify-center`}>Auto Confiança</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Flexibilidade</div>
              <div className={`text-center p-4  `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4`}>Padrão de Trabalho</div>
                <div className={`text-center p-4  `}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4   `}>Foco em Detalhe</div>
                <div className={`text-center p-4  `}>Liderança</div>
                <div className={`text-center p-4  `}>Pensamento Analítico</div>
                <div className={`text-center p-4 `}>Positividade</div>
                <div className={`text-center p-4  `}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16   text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Negociação</div>
                <div className={`text-center p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4  `}>Relacionamento Interpessoal</div>
                <div className={`text-center p-4 `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center bg-red-500 text-white  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4  `}>Gerenciamento de Processos</div>
                <div className={`text-center p-4  bg-red-500 text-white`}>Networking</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Peseverança</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Resolução de Conflitos</div>
                <div className={`text-center p-4  `}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`  -16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 `}>Gestão do Tempo</div>
                <div className={`text-center p-4  `}>Paciência</div>
                <div className={`text-center p-4 `}>Persuasão</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}
const Profile9 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Previsível'},
        {id:2,text:'Planejador'},
        {id:3,text:'Determinado'},
        {id:4,text:'Firme'},
        {id:5,text:'Leal'},
        {id:6,text:'Persistente'},
        {id:7,text:'Estável'},
        {id:7,text:'Racional'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-center justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        De acordo com sua auto avaliação, seu perfil natural é de um sólido
planejador, previsível e demonstra estabilidade e firmeza: isso inspira calma e
serenidade ao seu redor. Pessoas com esse perfil são percebidos como fortes
apaziguadores de conflitos - usualmente oferecem valioso suporte em
momentos de tensão e crise.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
      Tende a ser tenaz, persistente e tradicional, usando tempo e cautela para
tomar decisões, avaliando cuidadosamente as alternativas e as opiniões que
emite.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Em geral preservador e reservado, é um valioso colaborador para a criação
de um ambiente de trabalho de harmonia e estabilidade.
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
        *De acordo com o que você entende ser suas menores preferências, você se vê
como impulsivo e audacioso em algumas circunstancias.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
        Persistência é seu lema! Pessoas com o seu perfil tendem a não desistir. Por isso
são confiáveis o suficiente para que realizem todas as tarefas propostas mesmo
aquelas que possam ser consideradas pelos demais perfis como monótonas ou
repetitivas. Valorizam e defende o ambiente a seu redor e seu círculo social.

        </p> 
                    
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Geralmente, por ser planejador e firme, pode ser percebido como mais lento
no processo de tomada de decisão e ação. Comunicar seu estilo e processo
decisório a seus pares poderá ajudá-lo a resolver este item.
        </HighlightedParagraphWithIcon>    
   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
            Sua tendência a persistência poderá ser um elemento valioso ao demonstrar o
bom ouvinte que você é, e os benefícios que esse fato poderão trazer ao grupo.
Cuide de demonstrar essa qualidade.
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Sua tendência a persistir com tenacidade pode ser percebida como teimosia
por aqueles que tem perfis muito distintos. Garanta boa comunicação com seus
pares que logo perceberão esta qualidade!
        </HighlightedParagraphWithIcon>  

             <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>

        <div className={`grid lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={`   h-16 text-center p-4 flex items-center justify-center`}>Auto Confiança</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Flexibilidade</div>
              <div className={`text-center p-4  `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4`}>Padrão de Trabalho</div>
                <div className={`text-center p-4  `}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4   `}>Foco em Detalhe</div>
                <div className={`text-center p-4  `}>Liderança</div>
                <div className={`text-center p-4  `}>Pensamento Analítico</div>
                <div className={`text-center p-4 `}>Positividade</div>
                <div className={`text-center p-4  `}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16   text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Negociação</div>
                <div className={`text-center p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4  `}>Relacionamento Interpessoal</div>
                <div className={`text-center p-4 `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center bg-red-500 text-white  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4  `}>Gerenciamento de Processos</div>
                <div className={`text-center p-4  bg-red-500 text-white`}>Networking</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Peseverança</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Resolução de Conflitos</div>
                <div className={`text-center p-4  `}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`  -16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 `}>Gestão do Tempo</div>
                <div className={`text-center p-4  `}>Paciência</div>
                <div className={`text-center p-4 `}>Persuasão</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}
const Profile10 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Cuidadoso'},
        {id:2,text:'Disciplinado'},
        {id:3,text:'Preciso'},
        {id:4,text:'Cooperativo'},
        {id:5,text:'Respeitoso'},
        {id:6,text:'Formal'},
        {id:7,text:'Orientado para procedimentos'},
        {id:7,text:'Diplomático'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-center justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
Você é percebido como extremamente organizado, disciplinado e cuidadoso
na realização das tarefas, com foco na qualidade do trabalho.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
     Naturalmente orientado para os procedimentos e garantia da complementação
de tarefas, tende a ser bastante analítico, buscando sempre superar as
expectativas na entrega do trabalho.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
Normalmente mais reservado e moderado, é considerado muito cooperativo,
reverente e respeitoso e um ótimo ouvinte que cuida de manifestar suas
opiniões divergentes com muita cautela. Procura basear suas decisões e
opiniões em análise criteriosa e cuidadosa dos detalhes, dos fatos e dados.
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
        *De acordo com o que você entende ser suas menores preferências, pode ser
percebido como reservado e moderado em seus posicionamentos.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
        CONEXÃO é a característica forte atribuída a seu perfil - demonstra prezar muito
pelo apoio de colegas e busca manter um ambiente positivo com seus
relacionamentos profissionais. Muito orientado para regras e procedimentos, usa
estes regulamentos como base para apoiar suas ideias e seus movimentos
profissionais com segurança.

        </p> 
                    
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Por sua característica perfeccionista, demonstra tendência a preferir trabalhar
individualmente ou ter certa dificuldade em delegar, já que gosta da perfeição.
O fato de ter conhecimento deste traço facilitará a gestão junto aos demais
colegas com perfis distintos.
        </HighlightedParagraphWithIcon>    
   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
            Pelas preferencias por disciplina e orientação para procedimentos, poderá
demonstrar algum nível de stress quando as coisas não saem perfeitas ou se
decisões novas são tomadas ao longo do processo, fora do procedimento ou
até mesmo quando novas ideias aparecem repentinamente. Neste caso,
poderá correr o risco de ser percebido como inflexível ou demorar demais para
entrar em ação – entender o perfil e as tendências e trabalhar na comunicação
ajudará a monitorar as atitudes junto ao grupo.
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Cuide de equilibrar a vida pessoal e profissional!
        </HighlightedParagraphWithIcon>  
             
        <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>

        <div className={`grid   lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={`   h-16 text-center e p-4 flex items-center justify-center`}>Auto Confiança</div>
                <div className={`text-center p-4 `}>Flexibilidade</div>
              <div className={`text-center p-4  `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Padrão de Trabalho</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Foco em Detalhe</div>
                <div className={`text-center p-4  `}>Liderança</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Pensamento Analítico</div>
                <div className={`text-center p-4 `}>Positividade</div>
                <div className={`text-center p-4    `}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16   text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4   `}>Negociação</div>
                <div className={`text-center   p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4  `}>Relacionamento Interpessoal</div>
                <div className={`text-center  p-4  `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Gerenciamento de Processos</div>
                <div className={`text-center p-4 `}>Networking</div>
                <div className={`text-center p-4  `}>Peseverança</div>
                <div className={`text-center p-4 `}>Resolução de Conflitos</div>
                <div className={`text-center p-4  `}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Gestão do Tempo</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Paciência</div>
                <div className={`text-center p-4 `}>Persuasão</div>
                <div className={`text-center p-4  `}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}
const Profile11 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Cauteloso'},
        {id:2,text:'Meticuloso'},
        {id:3,text:'Disciplinado'},
        {id:4,text:'Preciso'},
        {id:5,text:'Respeitoso'},
        {id:6,text:'Precavido'},
        {id:7,text:'Perfeccionista'},
        {id:7,text:'Lógico'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-center justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Você é percebido como um perfeccionista por natureza, meticuloso e bastante
cauteloso com foco na qualidade na realização das tarefas.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
     Naturalmente orientado para os procedimentos, tem fortes tendências a ser
percebido como preciso e bastante analítico, prezando a exatidão em tudo que
faz, buscando atender e superar as expectativas na entrega do trabalho.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
      Normalmente mais reservado, reflexivo e formal, demonstra preferência pela
lógica, racionalização e exatidão. Procura basear suas decisões e opiniões em
análise criteriosa e cuidadosa dos detalhes, dos fatos e dados, sendo um
membro precioso para as equipes pela qualidade da sua entrega.
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
        *De acordo com o que você entende ser suas menores preferências, pode ser
percebido como racional, reservado e bastante reflexivo em seus
posicionamentos.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
        Exatidão é seu lema preferencial! Vc demonstra sempre muito cuidado e muita
cautela na entrega de seus trabalhos, pois não admite cometer erros. Naturalmente,
fica atento a erros dos outros também e tende a emitir parecer de correção apesar de
sempre com muito respeito e cuidado para ajudar na exatidão dos resultados. Sua

comunicação tende a ser reservada.

        </p> 
                    
      
          <p>
               Todos nós devemos cuidar das percepções junto aos demais. Por isso, para suporte em
                seu desenvolvimento e crescimento contínuo, seguem algumas dicas, que foram
                ressaltadas através de sua auto avaliação e que poderão ajudar ainda mais no seu
                sucesso profissional!
          </p>
   
   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Por sua característica perfeccionista, muito meticuloso e preciso pode
demonstrar tendência a preferir trabalhar individualmente ou ter certa
dificuldade em delegar, já que gosta da perfeição.
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Sendo racional como sua auto-avaliação aponta, estar ciente desse traço
facilitará a interação junto aos demais colegas com perfis distintos.
        </HighlightedParagraphWithIcon>  


        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
         Pelas preferencias pela racionalidade, exatidão e uniformidade, poderá ser
percebido como lento nos processos decisórios pois necessita ter todos os
dados para sua tomada de decisão. Pode demonstrar algum nível de stress
quando as coisas não saem perfeitas ou se decisões novas são tomadas ao
longo do processo por fugir da estrutura estabelecida. Neste caso, poderá
correr o risco de ser percebido como inflexível ou demorar demais para entrar
em ação – entender o perfil e as tendências e trabalhar na comunicação
ajudará a monitorar as atitudes junto ao grupo.
        </HighlightedParagraphWithIcon>  
           
        <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>
        <div className={`grid lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={`   h-16 text-center e p-4 flex items-center justify-center`}>Auto Confiança</div>
                <div className={`text-center p-4 `}>Flexibilidade</div>
              <div className={`text-center p-4  `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Padrão de Trabalho</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Foco em Detalhe</div>
                <div className={`text-center p-4  `}>Liderança</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Pensamento Analítico</div>
                <div className={`text-center p-4 `}>Positividade</div>
                <div className={`text-center p-4    `}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16   text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4   `}>Negociação</div>
                <div className={`text-center   p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4  `}>Relacionamento Interpessoal</div>
                <div className={`text-center  p-4  `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Gerenciamento de Processos</div>
                <div className={`text-center p-4 `}>Networking</div>
                <div className={`text-center p-4  `}>Peseverança</div>
                <div className={`text-center p-4 `}>Resolução de Conflitos</div>
                <div className={`text-center p-4  `}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Gestão do Tempo</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Paciência</div>
                <div className={`text-center p-4 `}>Persuasão</div>
                <div className={`text-center p-4  `}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}
const Profile12 = ({name}) => {

    const listOfHighlights = useRef([
        {id:1,text:'Detalhista'},
        {id:2,text:'Meticuloso'},
        {id:3,text:'Análitico'},
        {id:4,text:'Investigativo'},
        {id:5,text:'Perfeccionista'},
        {id:6,text:'Exigente'},
        {id:7,text:'Observador'},
        {id:7,text:'Disciplinado'},       
        
    ])

return(
 <div className={`flex items-start justify-center flex-col gap-8`}>

          
        Olá {name},

        <p>
            Com base na auto avaliação que você acaba de realizar, resumimos e destacamos
            alguns aspectos das preferências de seu comportamento que podem ajudar no seu
            auto conhecimento e no auto desenvolvimento.
            Sempre lembrando que você é único e nunca poderá ser categorizado em palavras, é
            impossível simplificar a complexidade de uma pessoa.
        </p>

        <div className={`flex flex-col items-center justify-center gap-4`}>
            
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
        Você é percebido como um grande observador, meticuloso , com excelente
visão de detalhes – o que reforça a percepção de garantia de qualidade na
realização das tarefas.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
      Naturalmente orientado para os procedimentos estruturados, tende a ser
bastante analítico e investigativo, colocando energia na precisão em tudo que
faz, buscando atender e superar as expectativas na entrega do trabalho.
        </HighlightedParagraphWithIcon>    

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
      Ativo e impetuoso, demonstra ser muito suscetível a mudanças no ambiente,
demonstra preferência pela lógica, racionalização e exatidão. Procura basear
suas decisões e opiniões em análise criteriosa e cuidadosa dos fatos e dados,
sendo um membro valioso para as equipes pela qualidade da sua entrega e
garantia das análises prévias.
        </HighlightedParagraphWithIcon>    

        </div>    

        
            
        
        <p>
        *De acordo com o que você entende ser suas menores preferências, pode ser
percebido como inquieto e com alto nível de exigência.
        </p>
        <p>
            Seguem aqui, algumas palavras que são percebidas usualmente em seu
            comportamento natural.
        </p>
            <div className={`border p-4 gap-2 w-full grid grid-cols-3 `}>
            
        
                {
                    listOfHighlights.current.map((highlight) => {
                        return (
                            <HighlightQualityTag key={highlight.index}>
                                 {highlight.text}
                            </HighlightQualityTag>    
                        )
                    })
                }           

            </div> 

 
        <p>
        PERCEPTIVIDADE é a grande característica percebida em você! E com este perfil,
se mostra muito atento ao ambiente à sua volta e mudanças que ocorram, mesmo que
mínimas. Demonstra esforço para que estas alterações não causem distrações ou
perda de foco pois prima pela qualidade extrema do que entrega.

        </p> 
                    
  
          <p>
               Todos nós devemos cuidar das percepções junto aos demais. Por isso, para suporte em
                seu desenvolvimento e crescimento contínuo, seguem algumas dicas, que foram
                ressaltadas através de sua auto avaliação e que poderão ajudar ainda mais no seu
                sucesso profissional!
          </p>

   
        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
           Por sua característica de observador com excelente visão de detalhes,
demonstra ser analítico e investigativo de fatos e dados com tendência a
preferir trabalhar individualmente ou ter certa dificuldade em delegar, já que
gosta da perfeição. Porém, sua energia e gosto por diversidade tende a facilitar
a interação junto aos demais colegas com perfis distintos.
        </HighlightedParagraphWithIcon>  

        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
          Demonstra sensibilidade a mudanças no ambiente pois fogem da estrutura e
procedimentos pelos quais tem preferência. Pode ser percebido como lento
para decidir ou agir já que sente necessidade de ter todos os fatos e dados para
tomada de decisão.
        </HighlightedParagraphWithIcon>  


        <HighlightedParagraphWithIcon icon={
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        }>
          Quando as coisas não saem perfeitas ou se decisões novas são tomadas ao
longo do processo fugindo da estrutura pré-estabelecida, pode demonstrar
nível de alta exigência. Porém, demonstra flexibilidade e energia suficientes
para que a entrega e o relacionamento com a equipe não sejam afetados.
Comunicar e alinhar expectativas é fundamental.
        </HighlightedParagraphWithIcon>  
        <p>
            Selecionamos abaixo algumas competências / Soft Skills mais desejadas pelos
            recrutadores do momento. São elementos subjetivos e portanto difíceis de serem
            avaliados. No entanto, pessoas com o seu perfil comportamental tendem a
            demonstrar um desenvolvimento mais natural e preferencial por algumas delas, que
            aparecerão em destaque pra você. Mas não deixe de conhecer as demais como desafio
            para seu sucesso pessoal até porque sua percepção deverá ser diferente.
        </p>

        <div className={`grid lg:grid-cols-5 grid-cols-1 align-items-start`}>
            <div className={`grid grid-cols-1 place-items-stretch align-items-center`}>
                <div className={`   h-16 text-center e p-4 flex items-center justify-center`}>Auto Confiança</div>
                <div className={`text-center p-4 `}>Flexibilidade</div>
              <div className={`text-center p-4  `}>Habilidade de Direcionamento</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Padrão de Trabalho</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Planejamento</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Capacidade de Inspirar</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Foco em Detalhe</div>
                <div className={`text-center p-4  `}>Liderança</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Pensamento Analítico</div>
                <div className={`text-center p-4 `}>Positividade</div>
                <div className={`text-center p-4    `}>Solução de Problemas</div>
            </div>
            <div className={`grid  grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16   text-center  p-4 flex items-center justify-center`}>Comunicação</div>
                <div className={`text-center p-4 `}>Foco em Resultado</div>
                <div className={`text-center p-4   `}>Negociação</div>
                <div className={`text-center   p-4 `}>Pensamento Crítico</div>
                <div className={`text-center p-4  `}>Relacionamento Interpessoal</div>
                <div className={`text-center  p-4  `}>Tomada de Decisão</div>
            </div> 
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={`h-16 text-center  p-4 flex items-center justify-center`}>Empatia</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Gerenciamento de Processos</div>
                <div className={`text-center p-4 `}>Networking</div>
                <div className={`text-center p-4  `}>Peseverança</div>
                <div className={`text-center p-4 `}>Resolução de Conflitos</div>
                <div className={`text-center p-4  `}>Trabalho em Equipe</div>
            </div>
            <div className={`grid grid-cols-1 place-items-stretch justify-center align-items-center`}>
                <div className={` h-16 text-center  p-4 flex items-center justify-center`}>Empreendedorismo</div>
                <div className={`text-center p-4 bg-red-500 text-white `}>Gestão do Tempo</div>
                <div className={`text-center p-4 bg-red-500 text-white`}>Paciência</div>
                <div className={`text-center p-4 `}>Persuasão</div>
                <div className={`text-center p-4  `}>Sensibilidade</div>
            </div>

        </div>
   </div>
    )
}

export default function SelfPerfilResults({resultId, answers, email}) {

    // fetch the url https://api.russianwarcrimes.com/wp-json/warcrimesapi/v1/warcrimes/numberofwarcrimes=1&skip=1 and log the return
    const fetchApi = ()=>{
        fetch(`https://api.russianwarcrimes.com/wp-json/warcrimesapi/v1/warcrimes/numberofwarcrimes=1&skip=1`)
        .then(res=>res.json())
        .then(data=>console.log(data))
    }

    const testEmail = (foo)=> {
        fetch(`https://api.quemindiq.com.br/wp-json/emailsender/v1/selfperfilresults/email=${email.current}&result=${returnWhichProfileNumberEqualThisResult(foo)}`, {
            method: 'GET',       
        })
                    .then(res => res.json())
                    .then(data => console.log(data))
                    .catch(err => console.log(err))         
        
    }

    const gabaritoArrayRef = useRef([
        {
            id: "1",
            a:"yellow",
            b:"blue",
            c:"green",
            d:"red"            
        },
        {
            id: "2",
            a:"green",
            b:"red",
            c:"blue",
            d:"yellow"            
        },
        {
            id: "3",
            a:"red",
            b:"yellow",
            c:"green",
            d:"blue"            
        },   
        {
            id: "4",
            a:"yellow",
            b:"blue",
            c:"red",
            d:"green"            
        },
        {
            id: "5",
            a:"green",
            b:"yellow",
            c:"blue",
            d:"red"            
        },
        {
            id: "6",
            a:"yellow",
            b:"green",
            c:"blue",
            d:"red"            
        },
        {
            id: "7",
            a:"blue",
            b:"green",
            c:"yellow",
            d:"red"            
        },
        {
            id: "8",
            a:"blue",
            b:"red",
            c:"yellow",
            d:"green"            
        },
         {
            id: "9",
            a:"yellow",
            b:"red",
            c:"blue",
            d:"green"            
        },
        {
            id: "10",
            a:"red",
            b:"yellow",
            c:"green",
            d:"blue"            
        },
        {
            id: "11",
            a:"red",
            b:"green",
            c:"yellow",
            d:"blue"            
        },
        {
            id: "12",
            a:"red",
            b:"blue",
            c:"green",
            d:"yellow"            
        },
           {
            id: "13",
            a:"blue",
            b:"red",
            c:"yellow",
            d:"green"            
        },
        {
            id: "14",
            a:"blue",
            b:"red",
            c:"green",
            d:"yellow"            
        },
        {
            id: "15",
            a:"yellow",
            b:"red",
            c:"blue",
            d:"green"            
        },
        {
            id: "16",
            a:"blue",
            b:"green",
            c:"yellow",
            d:"red"            
        },
        {
            id: "17",
            a:"blue",
            b:"green",
            c:"red",
            d:"yellow"            
        },
        {
            id: "18",
            a:"red",
            b:"blue",
            c:"yellow",
            d:"green"            
        },
        {
            id: "19",
            a:"yellow",
            b:"blue",
            c:"red",
            d:"green"            
        },
        {
            id: "20",
            a:"yellow",
            b:"blue",
            c:"red",
            d:"green"            
        },
        {
            id: "21",
            a:"yellow",
            b:"blue",
            c:"red",
            d:"green"            
        },
        {
            id: "22",
            a:"yellow",
            b:"green",
            c:"blue",
            d:"red"            
        },
        {
            id: "23",
            a:"yellow",
            b:"green",
            c:"red",
            d:"blue"            
        },
        {
            id: "24",
            a:"red",
            b:"green",
            c:"yellow",
            d:"blue"            
        },
        {
            id: "25",
            a:"yellow",
            b:"green",
            c:"blue",
            d:"red"            
        },
        {
            id: "26",
            a:"yellow",
            b:"green",
            c:"red",
            d:"blue"            
        },
        {
            id: "27",
            a:"red",
            b:"green",
            c:"yellow",
            d:"blue"            
        },
        {
            id: "28",
            a:"yellow",
            b:"green",
            c:"blue",
            d:"red"            
        },
        {
            id: "29",
            a:"yellow",
            b:"green",
            c:"red",
            d:"blue"            
        },
        {
            id: "30",
            a:"red",
            b:"green",
            c:"yellow",
            d:"blue"            
        },
           {
            id: "31",
            a:"green",
            b:"yellow",
            c:"red",
            d:"blue"            
        },
        {
            id: "32",
            a:"green",
            b:"red",
            c:"blue",
            d:"yellow"            
        },     
        {
            id: "33",
            a:"yellow",
            b:"blue",
            c:"green",
            d:"red"            
        },
    ])
   

    const [userCalculatedProfile, setUserCalculatedProfile] = useState(JSON.stringify([null,null]))

    const [userScore, setuserScore] = useState({
        red:0,
        yellow:0,
        blue:0,
        green:0
    })


    const returnWhichProfileNumberEqualThisResult = (result) =>{
        const resultAsString = JSON.stringify(result)       
        console.log('resultAsString', resultAsString)
        switch (resultAsString) {
            case JSON.stringify(["red", "yellow"]):            
                return 1                 
            case JSON.stringify(["red", "green"]):            
                return 2                 
            case JSON.stringify(["red", "blue"]):            
                return 3
            case JSON.stringify(["yellow", "red"]):            
                return 4
            case JSON.stringify(["yellow", "green"]):            
                return 5
            case JSON.stringify(["yellow", "blue"]):             
                return 6
            case JSON.stringify(["green", "red"]):            
                return 7  
            case JSON.stringify(["green", "yellow"]):            
                return 8
            case JSON.stringify(["green", "blue"]):            
                return 9
            case JSON.stringify(["blue", "red"]):            
                return 10
            case JSON.stringify(["blue", "yellow"]):            
                return 11
            case JSON.stringify(["blue", "green"]):            
                return 12                            
            default:
                return null              
        }
    }

    const loadPredefinedProfile = (resultId)=>{
        const profileId = parseInt(resultId)  
        switch (profileId) {
            case 1:            
                setUserCalculatedProfile(JSON.stringify(["red", "yellow"]))    
                break;   
            case 2:            
                setUserCalculatedProfile(JSON.stringify(["red", "green"]))    
                break;  
            case 3:            
                setUserCalculatedProfile(JSON.stringify(["red", "blue"]))    
                break;  
            case 4:            
                setUserCalculatedProfile(JSON.stringify(["yellow", "red"]))    
                break;
            case 5:            
                setUserCalculatedProfile(JSON.stringify(["yellow", "green"]))    
                break;
            case 6:             
                setUserCalculatedProfile(JSON.stringify(["yellow", "blue"]))    
                break;
            case 7:            
                setUserCalculatedProfile(JSON.stringify(["green", "red"]))    
                break;   
            case 8:            
                setUserCalculatedProfile(JSON.stringify(["green", "yellow"]))    
                break; 
            case 9:            
                setUserCalculatedProfile(JSON.stringify(["green", "blue"]))    
                break;
            case 10:            
                setUserCalculatedProfile(JSON.stringify(["blue", "red"]))    
                break;
            case 11:            
                setUserCalculatedProfile(JSON.stringify(["blue", "yellow"]))    
                break;
            case 12:            
                setUserCalculatedProfile(JSON.stringify(["blue", "green"]))    
                break;           
                     
            default:
                break;
        }
    }

    const calculateResults = ()=>{
        let i = 0
        let fooResults = {
           red:0,
           yellow:0,
           blue:0,
           green:0
        }
               
            answers.current.forEach((option)=>{  
              
                let firstChoiceRespectiveLetter = null
                switch (Number(option.firstChoice)) {
                    case 1:
                        firstChoiceRespectiveLetter = "a"
                        break;
                    case 2:
                        firstChoiceRespectiveLetter = "b"
                        break;
                    case 3:
                        firstChoiceRespectiveLetter = "c"
                        break;
                    case 4:
                        firstChoiceRespectiveLetter = "d"
                        break;
                    default:
                        console.log('defaultFirst')
                        break;
                }

                let color = gabaritoArrayRef.current[option.questionId-1][firstChoiceRespectiveLetter]       
                fooResults[color] = fooResults[color] + 1   
   
                let secondChoiceRespectiveLetter = ""
                switch (Number(option.secondChoice)) {
                    case 1:
                        secondChoiceRespectiveLetter = "a"
                        break;
                    case 2:
                        secondChoiceRespectiveLetter = "b"
                        break;
                    case 3:
                        secondChoiceRespectiveLetter = "c"
                        break;
                    case 4:
                        secondChoiceRespectiveLetter = "d"
                        break;
                    default:
                        console.log('defaultSecond')
                        break;
                }

                let color2 = gabaritoArrayRef.current[option.questionId-1][secondChoiceRespectiveLetter]       
                fooResults[color2] = fooResults[color2] + 1   
                
                i++       
            })    

     
     
     if (i===answers.current.length){
          console.log("fooResults",fooResults)
          setuserScore(fooResults)
    }   
    }

    // useEffect to load results without calculating based on the url params
    useEffect(() => {
    
     if (resultId){
        loadPredefinedProfile(resultId)
     } else {
        calculateResults()
     }
     
    }, [])

    const loadCheck = useRef(false)

    useEffect(() => {
        if (loadCheck.current) {

            console.log(userScore)
            
            let plus = Math.max(userScore.red, userScore.yellow, userScore.blue, userScore.green)
            let minus = Math.min(userScore.red, userScore.yellow, userScore.blue, userScore.green)
            let foo = [null,null]

            if (plus === userScore.red){
                foo[0] = 'red'
            }
            if (plus === userScore.yellow){
                foo[0] = 'yellow'
            }
            if (plus === userScore.green){
                foo[0] = 'green'
            }
            if (plus === userScore.blue){
                foo[0] = 'blue'
            }
          

            if (minus === userScore.red){
                foo[1] = 'red'
            }
            if (minus === userScore.yellow){
                foo[1] = 'yellow'
            }
            if (minus === userScore.green){
                foo[1] = 'green'
            }
            if (minus === userScore.blue){
                foo[1] = 'blue'
            }          
         
            setUserCalculatedProfile(JSON.stringify(foo))
            console.log("foo",foo)
            console.log("returnedfoo",returnWhichProfileNumberEqualThisResult(foo))

            if(email.current)  {
            console.log(email.current)    
            console.log(`https://api.quemindiq.com.br/wp-json/emailsender/v1/selfperfilresults/email=${email.current}&result=${returnWhichProfileNumberEqualThisResult(foo)}`)
            fetch(`https://api.quemindiq.com.br/wp-json/emailsender/v1/selfperfilresults/email=${email.current}&result=${returnWhichProfileNumberEqualThisResult(foo)}`, {
                method: 'GET',      
            })
                        .then(res => res.json())
                        .then(data => console.log(data))
                        .catch(err => console.log(err))         
            }

            
        } 
        else {
            loadCheck.current = true
        }
    }, [userScore])
    
    
    
    const { SelfSvg } = useSelfPerfilApiContext()
  return (
    <div className={`w-full max-w-screen-lg p-4 flex items-center justify-center flex-col`}>
        
        <SelfPerfilProfile/>
        
        <button onClick={()=>{testEmail(12)}}>test</button>
          <SelfSvg />     
       {userCalculatedProfile === JSON.stringify([null,null]) && 
       <span>Calculando Resultados...</span>}
       {email.current && <p className={`text-xl mt-4 border p-4 font-bold`}>Nós enviamos um link do resultado abaixo para o email: {email.current}!</p>}
       <>
            { 
             userCalculatedProfile === JSON.stringify(["red", "yellow"]) &&
                <Profile1 name={email.current}/>
            }
            
            { 
             userCalculatedProfile === JSON.stringify(["red", "green"]) &&
                <Profile2 name={email.current}/>
            }          
            
            { 
             userCalculatedProfile === JSON.stringify(["red", "blue"]) &&
                <Profile3 name={email.current}/>
            }

            { 
             userCalculatedProfile === JSON.stringify(["yellow", "red"]) &&
                <Profile4 name={email.current}/>
            }

            { 
             userCalculatedProfile === JSON.stringify(["yellow", "green"]) &&
             <Profile5 name={email.current}/>
            }

             { 
             userCalculatedProfile === JSON.stringify(["yellow", "blue"]) &&
             <Profile6 name={email.current}/>
            } 
     
            { 
             userCalculatedProfile === JSON.stringify(["green", "red"]) &&
                <Profile7 name={email.current}/>
            }  
            { 
             userCalculatedProfile === JSON.stringify(["green", "yellow"]) &&
                <Profile8 name={email.current}/>
            }
             { 
             userCalculatedProfile === JSON.stringify(["green", "blue"]) &&
                <Profile9 name={email.current}/>
            } 
             { 
             userCalculatedProfile === JSON.stringify(["blue", "red"]) &&
                <Profile10 name={email.current}/>
            }
             { 
             userCalculatedProfile === JSON.stringify(["blue", "yellow"]) &&
                <Profile11 name={email.current}/>
            }
             { 
             userCalculatedProfile === JSON.stringify(["blue", "green"]) &&
                <Profile12 name={email.current}/>
            }   
       </>
        
    </div>
  )
}
