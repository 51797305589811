import React, {useRef, useEffect, useState} from 'react';
import gsap from "gsap";
import {TweenMax, Power3} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import '../css/App.css';
import Professionalhomecontent from '../components/contentcomponents/home/Professionalhomecontent';
import Institutionhomecontent from '../components/contentcomponents/home/Institutionhomecontent';
import HeroTypeofUserSelector from '../components/contentcomponents/home/HeroTypeofUserSelector'; 
import HeroProOrInstSelector from '../components/HomePage/HeroProOrInstSelector'


export default function HomePage(props) {
  
  const [state, setstate] = useState({
    shouldInstitutionContentBeVisible: false,
    shouldProfessionalContentBeVisible: false,
  })

const handleVisibilityOfContentsCallback = (stateName) => {

    setstate( (prevState) => ({
    ...prevState,
    [stateName]: !prevState[stateName]
    }))

}

  return (
    <div className="HomePage w-full">    
         <HeroProOrInstSelector parentState={state} callbackFunction={handleVisibilityOfContentsCallback} />          
         {state.shouldInstitutionContentBeVisible ? <Institutionhomecontent history={props.history} /> : null }
         {state.shouldProfessionalContentBeVisible ? <Professionalhomecontent history={props.history} /> : null}
      
    </div>
  );
}



