import React, { Component } from 'react';
import Lottie from 'react-lottie';
import SuccessLottieData from '../../lottie/64787-success.json';

class SuccessLottie extends Component {


  render(){

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: SuccessLottieData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div className="">
        <Lottie options={defaultOptions}
              height={300}
              width={300}
        />
      </div>
    )
  }
}

export default SuccessLottie
// this is the scrolling down lottie