import React, {useState, useEffect} from 'react'
import Container from '@material-ui/core/Container'
import bgProfissionais from "../../images/viaje-incentivos.jpg" 
import bgDefault from '../../images/bgdefault.jpg'
import bgDefault2 from '../../images/bgdefault2.webp'
import bgThird from '../../images/bgthird.jpg'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'
import Logo from '../../images/k2.png'
import EditorFormatListNumbered from 'material-ui/svg-icons/editor/format-list-numbered';
import Extralottie1 from '../lottiecomponents/Extralottie1';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import gsap from 'gsap'
import { useNavigate } from 'react-router-dom'

export default function HeroProOrInstSelector(props) {

    const navigate = useNavigate()     

    useEffect(() => {
        const tl = gsap.timeline() 
        tl.to('.logopageloader', 0.8,{
            opacity:1,
            y:5,
            scale: 0.9,
            ease: 'sine.out'
        })
        tl.to('.pageloaderh3', 0.6,{
            opacity:1,
            y:5,
            scale: 0.9,
            ease: 'sine.out',
            delay: -0.2
        }, )
        tl.to('.pageloadern', 0.6,{
            x:'100%',
            ease: 'sine.in',
            delay: 0.5,            
        }, )
        tl.to('.pageloader2', 0.6,{
            x:'100%',
            ease: 'sine.in',
            delay: -0.45,            
        }, )
       
    }, [])

    const [state, setstate] = useState({
        bg:bgDefault2,
        heroMode: null,
        loading: false
    })


    // Material Ui Styling
    const useStyles = makeStyles( (theme)=>({

        heroButton:{
            marginTop: '30px',
            padding: '15px',
            paddingLeft: '25px',
            paddingRight:'25px',
            fontSize: '1.2em',
        },

        typographyHeroH1:{
            color:'#ffffff', 
            textAlign: 'right',
            fontSize: '7em',   
            textShadow: '6px 6px 4px #000000a9',     
            opacity: '1',
        },

        typographyHeroH3:{
            color:'#ffffff', 
            textAlign: 'right',
            fontSize: '2.2em',   
            textShadow: '6px 6px 4px #000000a9',
            opacity: '1'     
        },
        [theme.breakpoints.down('sm')]: {
            typographyHeroH1:{                      
                textAlign: 'center',           
                fontSize: '12vw',        
                textShadow: '6px 6px 4px #000000a9'                       
            },
            typographyHeroH3:{          
                textAlign: 'center',
                fontSize: '7vw',    
                marginTop: '15px',             
            },
            heroButton:{ 
                fontSize: '4vw',
            },
          },
    }))

    const classes = useStyles();
    // End of Material Ui Styling

   const handleSideSelector = (newBg,mode,buttonType) => {
       if (state.loading===false) {
      // setstate((i) => ({...i, loading:true}))        
   
    
       if (buttonType==='profissional') {

        let tlProfissional = gsap.timeline()
       
        tlProfissional.fromTo('#animationTransitionWhiteSheet', 0.8,{
            x:'-100%',   
        },{
            x:'100%',
            ease: 'sin.out',    
        })
        tlProfissional.fromTo('#animationTransitionRedSheet', 0.8,{
            x:'-100%',   
        },{
            x:'100%',
            delay: -0.7,
            ease: 'sin.out',
            onComplete: () => {
                props.callbackFunction("shouldProfessionalContentBeVisible")
            } 
   
        })
        tlProfissional.to('body', 0,{      
            delay: -0.65, 
            onComplete: () => {     
                setstate((i) => ({...i, heroMode:mode, bg:newBg})) 
            }    
        })

      
       }
       if (buttonType==='instituicao') {

        let tlInstituicao = gsap.timeline()
       
        tlInstituicao.fromTo('#animationTransitionWhiteSheet', 0.8,{
            x:'-100%',   
        },{
            x:'100%',
            ease: 'sin.out',    
        })
        tlInstituicao.fromTo('#animationTransitionRedSheet', 0.8,{
            x:'-100%',   
        },{
            x:'100%',
            delay: -0.7,
            ease: 'sin.out',
            onComplete: () => {               
             props.callbackFunction("shouldInstitutionContentBeVisible")
            } 
   
        })
        tlInstituicao.to('body', 0,{      
            delay: -0.65, 
            onComplete: () => {     
                setstate((i) => ({...i, heroMode:mode, bg:newBg})) 
            }    
        })
  
       }    

       if (buttonType===null) {
    
       if (state.heroMode==='profissional') {
        let tlNull = gsap.timeline()
       
        tlNull.fromTo('#animationTransitionWhiteSheet', 0.8,{
            x:'100%',   
        },{
            x:'-100%',
            ease: 'sin.out',    
        })
        tlNull.fromTo('#animationTransitionRedSheet', 0.8,{
            x:'100%',   
        },{
            x:'-100%',
            delay: -0.7,
            ease: 'sin.out',
            onComplete: () => {               
                props.callbackFunction("shouldProfessionalContentBeVisible")
            } 
   
        })
        tlNull.to('body', 0,{      
            delay: -0.65, 
            onComplete: () => {     
                setstate((i) => ({...i, heroMode:mode, bg:newBg})) 
            }    
        })

       }
       if (state.heroMode==='instituicao') {

        let tlNull = gsap.timeline()
       
        tlNull.fromTo('#animationTransitionWhiteSheet', 0.8,{
            x:'100%',   
        },{
            x:'-100%',
            ease: 'sin.out',    
        })
        tlNull.fromTo('#animationTransitionRedSheet', 0.8,{
            x:'100%',   
        },{
            x:'-100%',
            delay: -0.7,
            ease: 'sin.out',
            onComplete: () => {               
                props.callbackFunction("shouldInstitutionContentBeVisible") 
            } 
   
        })
        tlNull.to('body', 0,{      
            delay: -0.65, 
            onComplete: () => {     
                setstate((i) => ({...i, heroMode:mode, bg:newBg})) 
            }    
        })

       }        
       }    


    }
   }


 

   const ProfessionalSideHeroContent = () => {
        return(
            <div className="flex items-center md:items-end justify-center flex-col">
                <Typography noWrap={false} style={{ fontWeight: 600 }} className={classes.typographyHeroH1} variant="h1" color="initial">Buscamos Oportunidades</Typography>
                <Typography className={classes.typographyHeroH3} variant="h3" color="initial">Alcance mais longe com a Quemindiq</Typography>
                <Button size='large' className={classes.heroButton} onClick={() => {window.location.assign('https://app.quemindiq.com.br/entrar')}} variant="contained" color="primary">
                Entre em Contato Agora!
                </Button>
            </div>
        )
    }

    const InstitutionSideHeroContent = () => {
        return(
            <div className="flex items-center md:items-end justify-center flex-col">
                <Typography style={{ fontWeight: 600 }} className={classes.typographyHeroH1} variant="h1" color="initial">Buscamos Profissionais</Typography>
                <Typography className={classes.typographyHeroH3} variant="h3" color="initial">Sua maior aliada para SELEÇÕES CUSTOMIZADAS</Typography>
                <Button size='large' className={classes.heroButton}  onClick={() => {navigate('/contato')}} variant="contained" color="primary">
                Entre em Contato Agora!
                </Button>
            </div>
        )
    }

    const SideSelectorHeroContent = () => {
        return(
            <div className="z-20 flex items-center justify-around gap-8 relative">
                <div className={`absolute -top-20 right-50`}><img src={Logo} alt="" /></div>
             <Button variant="contained" color="primary"  size='large' className={classes.heroButton} onClick={() => {handleSideSelector(bgProfissionais, 'profissional', 'profissional') }}>Sou Profissional</Button>
             <Button variant="contained" color="primary"  size='large' className={classes.heroButton} onClick={() => {handleSideSelector(bgThird, 'instituicao', 'instituicao') }}>Sou Instituição</Button>
        
            </div>
        )
    }


    

    return (
        <div style={{width:'100vw', height:'100vh', zIndex:'50', backgroundImage:`url('${state.bg}')`, backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition: 'center' }} className="w-full flex items-center justify-center relative">
              <div id={`animationTransitionRedSheet`} className={`fixed -translate-x-full transform h-screen w-screen bg-red-500 z-50`}></div>
            <div id={`animationTransitionWhiteSheet`} className={`fixed flex-col gap-4 flex items-center justify-center -translate-x-full transform h-screen w-screen bg-white z-50`}>
      
            </div>
         
       

            <div className="pageloadern fixed top-0 right-0 h-screen w-screen z-50 bg-white flex items-center justify-center flex-col">
            <img className="logomenu logopageloader" src={Logo} alt="Logo"/>
            <h3 className="pageloaderh3">Conectando Profissionais da
            Educação</h3>
            </div>  
            <div className="pageloader2 bg-red-500 w-screen h-screen fixed top-0 bottom-0 z-40"></div>
       
        <div className={`absolute w-full h-full bg-black top-0 right-0 bg-opacity-20 z-10`}></div>
        <Container maxWidth="md">      
        { state.heroMode !== null &&  <div className="absolute top-2 left-2 z-20">
            <IconButton aria-label="Voltar" onClick={() => {handleSideSelector(bgDefault2, null, null)}}>
            <ArrowBackIcon style={{width:'40px', height:'40px', color:"#ffffff"}}/>
            </IconButton>
     </div> }
        
         <div className={`relative z-20`}>

             {state.heroMode==='instituicao' &&<InstitutionSideHeroContent/> }
             {state.heroMode==='profissional' && <ProfessionalSideHeroContent/>}
             {state.heroMode===null  && <SideSelectorHeroContent />}      
        
         </div>
         { state.heroMode !== null && <div className={`absolute w-full md:bottom-2 bottom-10 flex items-center justify-center right-0 z-10`}>  <Extralottie1 onClick={() => {alert('test')}}/></div> }
        </Container> 
        </div>
    )
}
