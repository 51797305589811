import React, {useRef} from 'react'
import SmallNumberDisplay from './SmallNumberDisplay'
import GraphCardDisplay from './GraphCardDisplay'

export default function Dashboard() {

  const data = useRef([
    {x: 0, y: 8},
    {x: 1, y: 5},
    {x: 2, y: 4},
    {x: 3, y: 9},
    {x: 4, y: 1},
    {x: 5, y: 7},
    {x: 6, y: 6},
    {x: 7, y: 3},
    {x: 8, y: 2},
    {x: 9, y: 0}
  ]);

  // 4 small cards at the top of the dashboard
  const smallNumberDisplayArray = useRef([
    {
      id:1,
      title:"Tests Done",
      icon:"fas fa-user-friends",
      number:200,
      oneLineSummaryOfChanges:<span>+55% more xablau</span>
    },
    {
      id:2,
      title:"New Accounts",
      icon:"fas fa-user-friends",
      number:24000,
      oneLineSummaryOfChanges:<span>+55% more xablau</span>
    },
    {
      id:3,
      title:"New Accounts",
      icon:"fas fa-user-friends",
      number:542,
      oneLineSummaryOfChanges:<span>+55% more xablau</span>
    },
    {
      id:4,
      title:"xax Accounts",
      icon:"fas fa-user-friends",
      number:542,
      oneLineSummaryOfChanges:<span>+55% more xablau</span>
    },
    
  ])

  // 3 graphs at middle of the dashboard
  const graphCardDisplayArray = useRef([
    {
      id: 1,
      title:"Website Views",
      subtitle:"Last Update: 20/06/2020",
      lastUpdate:"20/06/2020",
      graphType:"line",
      data:data.current
    },
    {
      id: 2,
      title:"Test Attempts",
      subtitle:"Last Update: 20/06/2020",
      lastUpdate:"20/06/2020",
      graphType:"heatmap",
      data:data.current
    },
    {
      id: 3,
      title:"Tests Unfinished",
      subtitle:"Last Update: 20/06/2020",
      lastUpdate:"20/06/2020",
      graphType:"area",
      data:data.current
    },

  ])

  return (
    <div>
      Dashboard
   <div className={`grid md:max-w-lg lg:max-w-5xl justify-items-center grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-2 `}>
        {
        smallNumberDisplayArray.current.map((element)=>{
          return(
              <SmallNumberDisplay
              key={element.id}
              title={element.title}
              icon={element.icon}
              number={element.number}
              oneLineSummaryOfChanges={element.oneLineSummaryOfChanges}
              />
          )
        })        
        }

   </div>

    <div className={`max-w-screen-lg grid justify-items-center grid-cols-1 lg:grid-cols-3 gap-2 `}>
      {
              graphCardDisplayArray.current.map((element)=>{
                return(
                    <GraphCardDisplay
                    key={element.id}
                    title={element.title}
                    subtitle={element.subtitle}
                    lastUpdate={element.lastUpdate}
                    graphType={element.graphType}
                    data={element.data}
                    />
                )
              })
      }        
    </div> 

    </div>
  )
}
