import React, {useRef, useEffect, useState} from 'react';
import '../../css/ThatPixFooter.css';
import Footerlogo from '../../images/logo_footer.png';
import Button from 'react-bootstrap/Button';


function ThatPixFooter() {
  return (
    <div className="ThatPixFooter">

      <div className="contentwrapper">

      <img src={Footerlogo} alt="Footer Logo" />
          <p>(21) 99597-9655</p>
          <p>(21) 99991-5595</p>

          <p>contato@quemindiq.com.br</p>
          <a href="https://admin.quemindiq.com.br/static/documents/termo_de_uso_quem_indiq.pdf"><button className="button1">Termos de Uso e Privacidade</button></a>

          <p id="copyrighttext">QuemIndiq © 2020. Todos os direitos reservados</p>
     


      </div>
    
      
       

    </div>
  );
}

export default ThatPixFooter;
