import React, { useState, useRef, useEffect } from 'react'
import { CardSelectorMoreOrLessIndetify } from '../components/SelfPerfilFrontEnd/CardSelectorMoreOrLessIdentify/CardSelectorMoreOrLessIndetify'
import gsap from 'gsap'
import { useSelfPerfilApiContext } from '../contexts/SelfPerfilAPIContext'
import { useNavigate, useLocation } from 'react-router-dom'
import SelfPerfilOptionSelector from '../components/SelfPerfilFrontEnd/CardSelectorMoreOrLessIdentify/SelfPerfilOptionSelector'


export function SelfPerfil() {
  
    const navigate = useNavigate()
    const location = useLocation()

    const { selfPerfilOptions, SelfSvg } = useSelfPerfilApiContext()
    const [progress, setprogress] = useState(0)    

    const [answers, setanswers] = useState([])


   const buttonText = "Enviar Escolhas"
   

   const handleSendAnswers = () => {
     if (progress>=66){      
      let capturedEmail = ""
      if (location.state && location.state.email) {
        capturedEmail = location.state.email
      }
      
      navigate('/selfperfil/myselfperfilresult', {state:{answers: answers, email:capturedEmail}})	
      window.scrollTo(0, 0)
     }    
     else {
       alert("Por favor, responda todas as perguntas antes de enviar suas respostas!")    
     }
   }
    

    return (
        <div className={`flex  flex-col gap-4 pt-4 pb-28 w-full min-h-screen items-center justify-center`}>
       
     <div className={`px-4`}>
          <div className={`w-full py-4 flex items-center justify-start border-b border-blue-500`}>
             <SelfSvg />
          </div>
          <div className={`font-sans flex flex-col items-start justify-centertext-base w-full gap-2`}>       
            <span>Para progredir, selecione em cada cartão a opção que você <strong>MAIS</strong> se identifica e depois a opção que você <strong>MENOS</strong> se identifica.</span>
            <span>Após fazer todas as suas escolhas, clique em "{buttonText}" no final da página.</span>
          </div>
     </div>

         <div className={`bg-blue-600`}></div>
         <div className={`bg-green-600`}></div>
          <div style={{width:progress/66 * 100 +'%'}} className={`fixed z-20 transition-all duration-150 ease-in-out left-0 bottom-0 h-4 bg-${progress >= 66 ? 'green' : 'blue'}-600`}></div>
            

         <div style={{minHeight: '450px'}} className={`grid lg:grid-cols-3 grid-cols-1 gap-4`}>
                {
                    selfPerfilOptions.map((element,index)=>{
                        return(
                        <div  key={element.id}>
                              <SelfPerfilOptionSelector
                               description ={element.description}       
                               arrayOfOptions={element.options} 
                               setprogress={setprogress}
                               progress={progress}
                               sizeOfAllOptions={33}                         
                               setanswers={setanswers}
                               answers={answers}
                               questionId={element.id}
                              />                       
                        </div>
                        )
                    })
                }
         </div>

      <div className={`w-full flex gap-4 items-center justify-center lg:justify-end`}>     
          <button onClick={()=>{handleSendAnswers()}} className={`px-4 text-white py-2  bg-red-500 rounded-md hover:bg-red-600 transition-color duration-200 ease-in-out cursor-pointer`}>
           {buttonText}
          </button>     
      </div>
            
        </div>
    )
}
