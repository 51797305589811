import React, {useContext, createContext, useRef} from 'react'
import {ReactComponent as SelfSvg} from '../images/selfsvg.svg'

const SelfPerfilApiContext = createContext()

export const useSelfPerfilApiContext = () => {
       return useContext(SelfPerfilApiContext)
}

export const SelfPerfilApiContextProvider = (props) => {

  const selfPerfilOptionsRef = useRef([
    {
    id: 1,
    options: [
        {
        id: 1,
        option:"Falante",
        status: "notSelected"
        },
        {
        id: 2,
        option:"Controlado",
        status: "notSelected"
        },
        {
        id: 3,
        option:"Convencional",
        status: "notSelected"
        },
        {
        id: 4,
        option:"Decidido",
        status: "notSelected"
        }
    ]
    },
    {
    id: 2,
    options: [  {
        id: 1,
        option:"Solícito",
        status: "notSelected"
        },
        {
        id: 2,
        option:"Decidido",
        status: "notSelected"
        },
        {
        id: 3,
        option: "Rigoroso",
        status: "notSelected"
        },
        {
        id: 4,
        option: "Alto Astral",
        status: "notSelected"
        }
        ]
    },
    {
    id: 3,
    options: [ {
        id: 1,
        option:"Corajoso",
        status: "notSelected"
        }, {
            id: 2,
            option: "Inspirador",
            status: "notSelected"
            }, {
                id: 3,
                option: "Submisso",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Reservado",
                    status: "notSelected"
                    },]
    },
    {
    id: 4,
    options: [ {
        id: 1,
        option:"Impulsivo",
        status: "notSelected"
        }, {
            id: 2,
            option: "Introvertido",
            status: "notSelected"
            }, {
                id: 3,
                option: "Enérgetico",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Descontraído",
                    status: "notSelected"
                    },]
    },
    {
    id: 5,
    options: [ {
        id: 1,
        option:"Educado",
        status: "notSelected"
        }, {
            id: 2,
            option: "Persuasivo",
            status: "notSelected"
            }, {
                id: 3,
                option: "Humilde",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Original",
                    status: "notSelected"
                    },]
    },
    {
    id: 6,
    options: [ {
        id: 1,
        option:"Encorajador",
        status: "notSelected"
        }, {
            id: 2,
            option: "Gentil",
            status: "notSelected"
            }, {
                id: 3,
                option: "Observador",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Independente",
                    status: "notSelected"
                    },]
    },
    {
    id: 7,
    options: [ {
        id: 1,
        option:"Disciplinado",
        status: "notSelected"
        }, {
            id: 2,
            option: "Generoso",
            status: "notSelected"
            }, {
                id: 3,
                option: "Dinâmico",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Persistente",
                    status: "notSelected"
                    },]
    },
    {
    id: 8,
    options: [ {
        id: 1,
        option:"Respeitoso",
        status: "notSelected"
        }, {
            id: 2,
            option: "Pioneiro",
            status: "notSelected"
            }, {
                id: 3,
                option: "Confiante",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Colaborador",
                    status: "notSelected"
                    },]
    },
    {
    id: 9,
    options: [ {
        id: 1,
        option:"Empolgado",
        status: "notSelected"
        }, {
            id: 2,
            option: "Ousado",
            status: "notSelected"
            }, {
                id: 3,
                option: "Diplomático",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Satisfeito",
                    status: "notSelected"
                    },]
    },
    {
    id: 10,
    options: [ {
        id: 1,
        option:"Provocativo",
        status: "notSelected"
        }, {
            id: 2,
            option: "Extrovertido",
            status: "notSelected"
            }, {
                id: 3,
                option: "Amável",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Temeroso",
                    status: "notSelected"
                    },]
    },    
    {
    id: 11,
    options: [ {
        id: 1,
        option:"Competitivo",
        status: "notSelected"
        }, {
            id: 2,
            option: "Ponderado",
            status: "notSelected"
            }, {
                id: 3,
                option: "Animado",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Discreto",
                    status: "notSelected"
                    },]
    },
    {
    id: 12,
    options: [ {
        id: 1,
        option:"Questionador",
        status: "notSelected"
        }, {
            id: 2,
            option: "Metódico",
            status: "notSelected"
            }, {
                id: 3,
                option: "Cooperador",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Despreocupado",
                    status: "notSelected"
                    },]
    },    
    {
    id: 13,
    options: [ {
        id: 1,
        option:"Precavido",
        status: "notSelected"
        }, {
            id: 2,
            option: "Determinado",
            status: "notSelected"
            }, {
                id: 3,
                option: "Convincente",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Atencioso",
                    status: "notSelected"
                    },]
    },
    {
    id: 14,
    options: [ {
        id: 1,
        option:"Lógico",
        status: "notSelected"
        }, {
            id: 2,
            option: "Audacioso",
            status: "notSelected"
            }, {
                id: 3,
                option: "Leal",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Carismático",
                    status: "notSelected"
                    },]
    },    
    {
    id: 15,
    options: [ {
        id: 1,
        option:"Irônico",
        status: "notSelected"
        }, {
            id: 2,
            option: "Ditatorial",
            status: "notSelected"
            }, {
                id: 3,
                option: "Acanhado",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Servil",
                    status: "notSelected"
                    },]
    },
    {
    id: 16,
    options: [ {
        id: 1,
        option:"Prudente",
        status: "notSelected"
        }, {
            id: 2,
            option: "Simpático",
            status: "notSelected"
            }, {
                id: 3,
                option: "Envolvente",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Perseverante",
                    status: "notSelected"
                    },]
    },    
    {
    id: 17,
    options: [ {
        id: 1,
        option:"Comedido",
        status: "notSelected"
        }, {
            id: 2,
            option: "Prestativo",
            status: "notSelected"
            }, {
                id: 3,
                option: "Convicto",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Entusiasmado",
                    status: "notSelected"
                    },]
    },
    {
    id: 18,
    options: [ {
        id: 1,
        option:"Aventureiro",
        status: "notSelected"
        }, {
            id: 2,
            option: "Sagaz",
            status: "notSelected"
            }, {
                id: 3,
                option: "Brincalhão",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Moderado",
                    status: "notSelected"
                    },]
    },
    
    {
    id: 19,
    options: [ {
        id: 1,
        option:"Cativante",
        status: "notSelected"
        }, {
            id: 2,
            option: "Pensativo",
            status: "notSelected"
            }, {
                id: 3,
                option: "Obstinado",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Previsível",
                    status: "notSelected"
                    },]
    },
    {
    id: 20,
    options: [ {
        id: 1,
        option:"Amigável",
        status: "notSelected"
        }, {
            id: 2,
            option: "Preciso",
            status: "notSelected"
            }, {
                id: 3,
                option: "Espontâneo",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Calmo",
                    status: "notSelected"
                    },]
    },
    
    {
    id: 21,
    options: [ {
        id: 1,
        option:"Comunicativo",
        status: "notSelected"
        }, {
            id: 2,
            option: "Sofisticado",
            status: "notSelected"
            }, {
                id: 3,
                option: "Veemente",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Condescendente",
                    status: "notSelected"
                    },]
    },
    {
    id: 22,
    options: [ {
        id: 1,
        option:"Confiável",
        status: "notSelected"
        }, {
            id: 2,
            option: "Solidário",
            status: "notSelected"
            }, {
                id: 3,
                option: "Imparcial",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Assertivo",
                    status: "notSelected"
                    },]
    },
    
    {
    id: 23,
    options: [ {
        id: 1,
        option:"Sociável",
        status: "notSelected"
        }, {
            id: 2,
            option: "Paciente",
            status: "notSelected"
            }, {
                id: 3,
                option: "Auto Confiante",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Carinhoso",
                    status: "notSelected"
                    },]
    },
    {
    id: 24,
    options: [ {
        id: 1,
        option:"Incansável",
        status: "notSelected"
        }, {
            id: 2,
            option: "Cortês",
            status: "notSelected"
            }, {
                id: 3,
                option: "Acolhedor",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Cuidadoso",
                    status: "notSelected"
                    },]
    },
    
    {
    id: 25,
    description: "Avalio meu desempenho com base",
    options: [ {
        id: 1,
        option:"NOS ELOGIOS QUE RECEBO",
        status: "notSelected"
        }, {
            id: 2,
            option: "NAS CONTRIBUIÇÕES DADAS",
            status: "notSelected"
            }, {
                id: 3,
                option: "NA QUALIDADE DOS RESULTADOS",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "NO MEU HISTÓRICO",
                    status: "notSelected"
                    },]
    },
    {
    id: 26,
    description: "Em situações de alto stress tendo a ficar",
    options: [ {
        id: 1,
        option:"Sarcástico",
        status: "notSelected"
        }, {
            id: 2,
            option: "Submisso",
            status: "notSelected"
            }, {
                id: 3,
                option: "Autoritário",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Arredio",
                    status: "notSelected"
                    },]
    },
    
    {
    id: 27,
    description: "Trabalho melhor quando tenho ou recebo",
    options: [ {
        id: 1,
        option:"CONTROLE DA SITUAÇÂO",
        status: "notSelected"
        }, {
            id: 2,
            option: "ROTINA PRÉ-ESTABELECIDA",
            status: "notSelected"
            }, {
                id: 3,
                option: "RECEBO FEEDBACK POSITIVO",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "PROCEDIMENTOS E PROCESSOS CLAROS",
                    status: "notSelected"
                    },]
    },
    {
    id: 28,
    description: "Em situações de conflito tendo a",
    options: [ {
        id: 1,
        option:"AGIR COM IMPULSIVIDADE",
        status: "notSelected"
        }, {
            id: 2,
            option: "LEVAR PARA O LADO PESSOAL",
            status: "notSelected"
            }, {
                id: 3,
                option: "EVITAR CONFRONTOS",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "DETERMINAR AÇÃO A SER TOMADA",
                    status: "notSelected"
                    },]
    },
    
    {
    id: 29,
    description: "Geralmente sou percebido como",
    options: [ {
        id: 1,
        option:"Otimista",
        status: "notSelected"
        }, {
            id: 2,
            option: "Prestativo",
            status: "notSelected"
            }, {
                id: 3,
                option: "Franco",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Detalhista",
                    status: "notSelected"
                    },]
    },
    {
        id: 30,
        description: "Tenho a preferir tarefas",
        options: [ {
            id: 1,
            option:"DESAFIADORAS",
            status: "notSelected"
            }, {
                id: 2,
                option: "RELACIONADAS A PESSOAS",
                status: "notSelected"
                }, {
                    id: 3,
                    option: "INOVADORAS",
                    status: "notSelected"
                    }, {
                        id: 4,
                        option: "ESTRUTURADAS",
                        status: "notSelected"
                        },]
        },
    {
    id: 31,
    description: "Percebo como meu maior ponto fraco",
    options: [ {
        id: 1,
        option:"Ser Tímido",
        status: "notSelected"
        }, {
            id: 2,
            option: "Ser desorganizado",
            status: "notSelected"
            }, {
                id: 3,
                option: "Ser direto demais",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Ser detalhista",
                    status: "notSelected"
                    },]
    },
    
    {
    id: 32,
    description: "Sinto necessidade de desenvolver",
    options: [ {
        id: 1,
        option:"Maior assertividade",
        status: "notSelected"
        }, {
            id: 2,
            option: "Empatia e paciência",
            status: "notSelected"
            }, {
                id: 3,
                option: "Capacidade de delegar",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Maior escuta ativa",
                    status: "notSelected"
                    },]
    },
    {
    id: 33,
    description:"Tendo a me auto avaliar como",
    options: [ {
        id: 1,
        option:"Quieto",
        status: "notSelected"
        }, {
            id: 2,
            option: "Perceptivo",
            status: "notSelected"
            }, {
                id: 3,
                option: "Modesto",
                status: "notSelected"
                }, {
                    id: 4,
                    option: "Afoito",
                    status: "notSelected"
                    },]
    }

  ])  

  const selfPerfilOptions = selfPerfilOptionsRef.current



  const selfPerfilAPI = "http://localhost:5001/quemindiqwebsite/us-central1/selfPerfilAPI"    
    
  function fetchSelfPerfilApi(apiDataToBeSent) {
 
        fetch(selfPerfilAPI,apiDataToBeSent)
        .then(response => response.json())
        .then(data => {
            console.log(data)
        })
        .catch(error => {
            console.log(error)
        })        
         
  }


  const value={
    fetchSelfPerfilApi,
    selfPerfilOptions,
    SelfSvg,
  }

    return(
        <SelfPerfilApiContext.Provider value={value}>
        <div>
            {props.children}
        </div>
        </SelfPerfilApiContext.Provider>
    )
}

