import React, { useRef, useState } from 'react'
import {useSelfPerfilApiContext} from '../contexts/SelfPerfilAPIContext'

export default function APItester() {

    const {fetchSelfPerfilApi} = useSelfPerfilApiContext()

    const apiDataToBeSent = useRef({
        method: 'post', 
        body: JSON.stringify({ 
            method: "get",
            target: "null",
            data: "null"
         }),
        headers: {
          "Content-Type": "application/json", 
        },
        // 
    })    

  return (
    <div>
        <button className={`bg-blue-400 hover:bg-blue-500 transition-colors duration-200 ease-in-out px-4 py-1`}
         onClick={()=>{fetchSelfPerfilApi(apiDataToBeSent.current)}}>
            testAPI
        </button>
    </div>
  )
}
