import React, { Component } from 'react';
import Lottie from 'react-lottie';
import Extralottie2Data from '../../lottie/24811-saving-money (1).json';

class Extralottie2 extends Component {


  render(){

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: Extralottie2Data,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

    return(
      <div>
        <Lottie options={defaultOptions}
              height={400}
              width={400}
        />
      </div>
    )
  }
}

export default Extralottie2