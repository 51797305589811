import '../css/App.css';

import React, {useRef, useEffect, useState} from 'react';


function MaintenancePage() {
  return (
    <div className="MaintenancePage">

      <h1 className="pagetitley">Maintenance</h1>
    
    <div className="monkeyworking"></div>

          <h2>
             A trained monkey named Charles is doing some work under the table!<br></br>    
                    
          </h2>

         
     
       

    </div>
  );
}

export default MaintenancePage;
