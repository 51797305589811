import './css/App.css';
import React from 'react';
import ContatoPage from './pages/contato';
import SobrenosPage from './pages/sobrenos';
import HomePage from './pages/home';
import MaintenancePage from './pages/maintenance';
import ThatPixNav from './components/standalone/ThatPixNav';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { SelfPerfil } from './pages/SelfPerfil';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import '../node_modules/react-vis/dist/style.css';
import APItester from './pages/APItester';
import {SelfPerfilApiContextProvider} from './contexts/SelfPerfilAPIContext';
import MySelfPerfilResult from './pages/MySelfPerfilResult';
import MaintenanceNew from './pages/MaintenanceNew'
 
import {
  BrowserRouter,
  Router,
  Routes,
  Route,
} from "react-router-dom";


function App() {

  const theme = createTheme({
    palette:{
      primary:{
        main:"#EE3635"
      }
    },
    typography: {
      fontFamily: 'Raleway, Arial',
      
    },
  });

  
  
  return (   
    <ThemeProvider theme={theme}>
    <div className="App ">
  
    <BrowserRouter>
    <SelfPerfilApiContextProvider>
    <ThatPixNav />
        
        
         <Routes>
          <Route path="/contato" element={<ContatoPage/>}>                    
          </Route>
          <Route path="/dashboard" element={<DashboardPage/>}>                    
          </Route>
          <Route path="/login" element={<LoginPage mode={"login"}/>}>       
                      
          </Route>
          <Route path="/nologin" element={<LoginPage mode={"noLogin"} /> }>     
                    
          </Route>

          <Route path="/manutencao" element={<MaintenanceNew /> }>     
                    
          </Route>
          
          <Route path="/selfperfil" exact element={<SelfPerfil/>}>                    
          </Route>
          <Route path="/selfperfil/myselfperfilresult" element={<MySelfPerfilResult/>}>                    
          </Route>
          <Route exact path={`/selfperfil/myselfperfilresult/:resultId`}
            element={<MySelfPerfilResult/>}
          />     
            
      
          <Route path="/apitester" element={<APItester/>}>                    
          </Route>
          <Route path="/maintenance" element={<MaintenancePage/>}>
          </Route>
          <Route path="/sobrenos" element={<SobrenosPage/>} >         
          </Route>
          <Route path="/" exact element={<HomePage/>}>         
          </Route>        
        </Routes>
    </SelfPerfilApiContextProvider>
    </BrowserRouter>

    </div>
    </ThemeProvider>
  );
}

export default App;
