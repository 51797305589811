import '../css/App.css';

import React, {useRef, useEffect, useState} from 'react';
import DefaultHeroSection from '../components/contentcomponents/defaultherosection.js';
import ThatPixFooter from '../components/standalone/ThatPixFooter';
import SobrePessoaComponent from '../components/SobreNosComponents/SobrePessoaComponent';
import Gilma from '../images/Imagem2.png'
import Marcia from '../images/Imagem1.png'
import {useLocation} from 'react-router-dom'


export default function SobrenosPage() {

  const location = useLocation()
  console.log(location)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  

  return (
    <div className="SobrenosPage w-screen h-auto overflow-x-hidden flex items-center flex-col justify-center">
       
    <DefaultHeroSection location={location} title="Sobre Nós"/> 

    <div className="mt-32 flex items-stretch justify-stretch"> 
     
         <div style={{maxWidth:'1200px'}} className="sobrenos-w md:w-3/4 flex flex-col md:flex-row items-stretch justify-stretch md:p-16 p-8 flex-1">
           
           
           <div className="left w-full h-full md:w-1/2 relative mb-0 md:mr-16 flex-1">

           {location.state ?
           location.state.fromProfessionalSide &&
              <SobrePessoaComponent
              imagePessoa={Gilma}
              nomePessoa="Gilma Silva Correa"
              tituloPessoa="Co-Fundadora"
              textoPessoa="Escolhi, por paixão e vocação, trabalhar na área de Educação, iniciando como
              professora e ocupando diversos cargos ao longo de duas décadas. Posteriormente,
              trilhei o caminho de gestão de talentos na área de Recursos Humanos na América
              Latina e concluí este ciclo com uma certeza: nosso segmento de educação, os
              profissionais que trabalham ou querem trabalhar e se dedicar a esta área precisam de
              suporte para se tornarem visíveis ao mercado, saber o que o mercado oferece e
              poderem buscar suas aspirações e realizações. Foi assim que nasceu a Quemindiq!"
              />
             :
             <SobrePessoaComponent
             imagePessoa={Gilma}
             nomePessoa="Gilma Silva Correa"
             tituloPessoa="Co-Fundadora"
             textoPessoa="Meu maior aprendizado ao longo de décadas de minha trilha na Educação e em Gestão
             de Gente é que pessoas são, inquestionavelmente, o pilar principal da
             sustentabilidade, excelência e crescimento de qualquer Instituição, em especial no
             segmento educacional. O valor de um processo seletivo customizado, a descrição com
             detalhes do perfil que se necessita, as responsabilidades e entregas esperadas são
             vitais. Na sequência, investigar no mercado quem são os profissionais que trazem não
             somente esta experiência, qualificação ou habilidades comportamentais, mas também
             anseios, expectativas e valores alinhados com a oportunidade oferecida e a cultura da
             Instituição. Isso em nada tem a ver com simplesmente analisar currículos ... tem a ver
             com selecionar talentos – este é o propósito da Quemindiq!"
             />
             }

            
            
                       

           </div>
           <div className="right w-full h-full md:w-1/2  relative md:mt-0 mt-32 flex-1">

           {location.state ?
           location.state.fromProfessionalSide &&
              <SobrePessoaComponent
              imagePessoa={Marcia}
              nomePessoa="Márcia Marques Goulart"
              tituloPessoa="Co-Fundadora"
              textoPessoa="Trabalhar com e pela Educação foi algo que aprendi desde que nasci pelas mãos de
              minha mãe, professora que amava sua profissão. Posso dizer que fui movida à paixão e
              inspiração. Minha vida profissional foi exclusivamente dedicada à área Educacional
              com maior ênfase no ensino de língua estrangeira, gerenciamento de equipes e
              implementação de programas bilingues em escolas parceiras. Com essa vivência, ficou
              muito claro que o profissional da Educação precisa de apoio para conhecer as
              oportunidades de movimentação de carreira, ampliar sua visibilidade e trilhar seu
              crescimento contínuo. A QuemIndiq poderá ajudá-lo a chegar mais longe!"
              />
             :
             <SobrePessoaComponent
             imagePessoa={Marcia}
             nomePessoa="Márcia Marques Goulart"
             tituloPessoa="Co-Fundadora"
             textoPessoa="Em minha experiência profissional ao longo de mais de 30 anos de história com
             exclusividade no segmento de Educação, ficou muito claro que a peça fundamental
             para boa implementação dos processos educacionais é a escolha de um profissional de
             excelência. Constatamos, no entanto, que a seleção de profissionais no segmento
             Educacional ainda está muito baseada em indicações internas informais e, dessa
             forma, a instituição pode perder a oportunidade competitiva de buscar o melhor
             profissional que se adeque aos requisitos técnicos e comportamentais de seus desafios
             e de sua cultura organizacional.
             Otimizamos seu tempo, realizamos buscas abrangentes e confidenciais e avaliamos as
             competências profissionais (hard skills) e comportamentais (soft skills) necessárias ao
             perfil . Entre em contato com a QuemIndiq e viva essa experiência!"
             />
             }

         
           </div>

      

         </div>
                  


    </div>

    {location.state  ?
           location.state.fromProfessionalSide &&
           <div className="callToActionSobreNos-w flex flex-col items-center  justify-center md:p-8 p-4 ">
                       <h1 className="text-center text-gray-800">Olá Profissional! Animado(a) para conhecer a plataforma QuemIndiq? Ainda não tem uma conta?</h1>
                       <h1 className="text-center mt-4 font-bold text-4xl text-gray-700 border-4 border-green-600 p-8">Cadastre-se Abaixo!</h1>
                        <button onClick={()=>{window.location.href="https://app.quemindiq.com.br/cadastro"}} className=" md:w-1/2 w-full mt-8  h-24 text-2xl text-gray-200 bg-green-600 hover:bg-green-800 font-bold font-sans ">Acessar Plataforma</button>
          </div> 
           :
           <div className="callToActionSobreNos-w flex flex-col items-center  justify-center md:p-8 p-4 ">
             <h1 className="text-center text-gray-800">Olá! Gostaria de experimentar a QuemIndiq? Ainda não entrou em contato?</h1>
             <h1 className="text-center mt-4 font-bold text-4xl text-gray-700 border-4 border-green-600 p-8">Fale conosco agora!</h1>
            <button onClick={()=>{window.location.href="/contato"}} className=" md:w-1/2 w-full mt-8  h-24 text-2xl text-gray-200 bg-green-600 hover:bg-green-800 font-bold font-sans ">Entre em Contato</button>
</div> 
           }
   
    <ThatPixFooter />       

 </div>
  )
}




