import React from 'react';
import {gsap, Power3} from 'gsap';
import '../../css/ThatPixNav.css';
import logo from '../../images/k2.png';
import iconhome from '../../images/home.svg';
import icongroup from '../../images/group.svg';
import iconphonecall from '../../images/phone-call.svg';


export default class ThatPixNav extends React.Component {

  constructor(props){
    super(props)
    this.state={
      menuState: false,     
      //States with "Exec" exist to prevent action until the animation is over
      //While the animation is being executed, while is 'true', the codes will not run
      //After the state is set to false during the end of the animation it will function again
      //As usually should. This is to prevent strange, buggy or unexpected behaviour.      
      //Both animations of having the menu cross forming and the menu opening happen together so a single
      //Exec was created for both  
      menuExecState: false,  
    }
  }

  
// Timelines declaration
//********************************************** Attention***********************************************
//*******************************************************************************************************
// There are the actual effects on componentDidMount respective for each of those timelines! Check there!
//*******************************************************************************************************
//*******************************************************************************************************
 tlMenu = gsap.timeline({repeat: 0, paused: true}); 
 tlCross = gsap.timeline({repeat: 0, paused: true}); 
 tlBackground = gsap.timeline({repeat: 0, paused: true});


 onMenuAnimationCompletion=()=>{
   // To be executed when the animation finishes happening
   this.setState({menuExecState:false})
}





  menuOOrC = ()=>{
  // The term 'OOrC' means 'Opening Or Closing' and I use it a lot instead of writing the
  // full term. Everytime you see 'OOrC' it means 'Opening Or Closing' 
    if (!this.state.menuExecState) {
      // This should activate when menuExecState is false, meaning it's not being executed
      // and should not activate when menuExectState is true, meaning it's being executed
      this.setState({menuExecState:true}, ()=>{

        //Now it's established that the animation was not in between an execution and it has been,
        //said that from now on it's being executed. So now it's time to check if the menu is actually open or closed.

        if (this.state.menuState) {
          // If this.state.menuState returns false, it means the menu was closed hence it will not open
          // and make this.state.menuState become true

          console.log('Animation of the menu closing');  
          this.menuAnimationOOrC()
          this.menuBackgroundOOrC()
          this.menuCrossOOrC()        
          this.setState({menuState:false})                  
        }
        else
        {
          console.log('Animation of the menu opening');    
          this.menuAnimationOOrC() 
          this.menuBackgroundOOrC()
          this.menuCrossOOrC()   
          this.setState({menuState:true})       
        }

          
      
    })   
    } else
    {
    console.log(`Could not Innitiate 'menuOOrc' because 'menuExecState' returned true. Should be able to be executed after the animation is done and 'menuExecState' start returning true again.`);  
    }

    

  }

  menuBackgroundOOrC =()=>{
    //hamburguer become the background of the menu
  
    if (this.state.menuState) {
      this.tlBackground.reverse()
    }
    else{

      this.tlBackground.play()
    

    }
  
  }

  menuCrossOOrC =()=>{  
//Bars from barwrapper become a cross 
if (this.state.menuState) {

  this.tlCross.reverse()
  
}
else{
 
  this.tlCross.play()

}
}

menuAnimationOOrC =()=>{  
  //Menu Appearing from it's hidden spot
  if (this.state.menuState) {
  
    this.tlMenu.reverse()
    
  }
  else{
   
    this.tlMenu.play()
  
  }
  }



 componentDidMount(){

 //Animation Details of each timeline.
 //There are only details about how the timeline will play forwards and not how it will play backwards
 //That's intentional as all animations will be reversed once we need to vanish with the menus, uncross bars from hamburguer
 //etc.  

 let circleGrowingFactor = null;
 let hamburguerAnimationDuration = 0.25;

 if (window.innerWidth>=window.innerHeight) {
  circleGrowingFactor = (window.innerHeight/window.innerWidth) * hamburguerAnimationDuration  

 } else {
  circleGrowingFactor = (window.innerWidth/window.innerHeight) * hamburguerAnimationDuration
 }

this.tlMenu.fromTo(".Menu", 0.2, {x:50, ease: `exp.out`, display:'none', opacity:0},{x:0, display:'grid', ease: `linear`, opacity:1}) 


this.tlBackground.to(".hamburguer", 0.25, {right:0, top:0, bottom:0, ease: 'linear'});
this.tlBackground.to(".hamburguer", 0.25, {delay:-0.25, borderRadius:0+'%', ease: 'linear'});
this.tlBackground.to(".hamburguer", 0.25, {delay:-0.25, height: 100 + "vh", ease: 'linear'});
this.tlBackground.to(".hamburguer", circleGrowingFactor, {delay: -0.25, width: 100 + "vw", ease: 'linear'});

 
 
 this.tlCross.to("#bar1", 1, {y:7, delay: 0, rotation: 45, ease: Power3.easeOut})
 this.tlCross.to("#bar2", 1,{y:0,  opacity: 0, ease: Power3.easeOut, delay:-1} )
 this.tlCross.to("#bar3", 1,{y:-7, rotation: -45, onReverseComplete:this.onMenuAnimationCompletion, onComplete:this.onMenuAnimationCompletion, ease: Power3.easeOut, delay:-1 })   

  }

render(){

  return (
<div className="ThatPixNav">
<div className="magnifier">
  <div className="magnifiercircle"></div>
  <div className="magnifiercable"></div>
  <div className="magnifierconnection"></div>
</div>  

<div className="barswrapper" onClick={this.menuOOrC}>

<div id="bar1"></div>
<div id="bar2"></div>
<div id="bar3"></div>


</div>


  <div className="hamburguer">
  
  
  </div>  

<div className="Menu">
  <div className="Area1">

    
     </div>
  <div className="Area2">

    </div>
  <div className="Area3">

    </div>
  <div className="Area4">

    </div>
  <div className="Area5">
  <img className="logomenu" src={logo} alt="Logo"/>

  <a href="/"><button className="menubuttonx">
  <div className="leftrightwrapper">
  <div className="icon"><img src={iconhome} alt="icon home"></img></div>
  Home</div> </button></a>    

  <a href="/sobrenos"><button className="menubuttonx">
  <div className="leftrightwrapper">
    <div className="icon icon2"><img src={icongroup} alt="icon sobre nos"></img></div>   
  Sobre Nós </div></button></a>
    
  <a href="/contato"><button className="menubuttonx">
    <div className="leftrightwrapper">
  <div className="icon"><img src={iconphonecall} alt="icon contato"></img></div>   
   Contato</div></button></a>
    
    
    <a href="/manutencao"><button>Entrar</button></a> <a href="/manutencao"><button>Cadastrar</button></a>
   </div>
  <div className="Area6"></div>
  <div className="Area7"></div>
  <div className="Area8"></div>
</div>
     
       

    </div>
  );
}
} 


