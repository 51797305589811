// This react component is what is what appear after the page loader vanishes
// The user may select two options to then proceed to visualize a page that better fit 
// his/her type.
import React, {useRef, useEffect, useState} from 'react';
import '../../../css/HeroTypeofUserSelector.css';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import {TweenMax, Power3, gsap, TimelineMax, Linear} from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Extralottie1 from '../../lottiecomponents/Extralottie1';
import logo from '../../../images/k2.png';
import { useNavigate } from 'react-router-dom'

function HeroTypeofUserSelector() {

 const navigate = useNavigate()     

 var tl1 = gsap.timeline({repeat: 0});
 var tl2 = gsap.timeline({repeat: 0});

 //arrowreverting
 var tl3 = gsap.timeline({repeat: 0});
 var tl4 = gsap.timeline({repeat: 0});
 


 let homestate = "makeachoice";

 function institutionbackarrow () {          

        if (window.innerWidth <= 600){

                  // Back arrow for the institution vanishes        
                  tl3.to("#backarrowinstitution", {alpha: 0, duration: 0.5,});
                  //Vanishes with the institutionhome content
                  tl3.to(".Institutionhomecontent", {display: "none", opacity: 0, duration: 0, delay:0, ease: Linear});
                  //Return Overlay to original shade
                  tl3.to(".darkoverlay", {opacity: 0.05, duration: 4, delay:0, ease: Linear});
                  //Vanishe with custom text and button
                  tl3.to("#textareaheroinstitution", {x:-10, opacity: 0, duration: 1, delay:-4});
                  // Scroll down lottie vanishes
                  tl3.to("#scrolldownarrowinstitutional", {opacity: 0, duration: 1, delay:-4});
                 // Make the heroselector fixed
                  tl3.to(".HeroTypeofUserSelector", {position:"fixed", top:0, delay:-4 ,  duration: 0});
                  //return Woman to the screen
                  tl3.to(".ladypositioner2", {x: 0, duration: 2, delay:-4, ease: Power3.easeInOut});
                  tl3.to(".ladypositioner1", {width: "50vw", duration: 1, delay: -4, ease: Linear}); 
                  tl3.to(".lady", {width: "50vw", duration: 1, delay: -4, ease: Linear});    
                  tl3.to("#professionalside", {width: "100%", x:0, opacity:1, duration: 1, delay: -4, ease: Linear});
                  //return buttons
                  tl3.to(".ToVanish", {opacity:1, duration: 0.5, delay:-3, ease: Linear});
                  tl3.to(".ToVanish", {display: "block", duration: 0, delay:-3, ease: Linear});
                
              
        }
        else
        {
                // Back arrow for the institution vanishes        
                tl3.to("#backarrowinstitution", {alpha: 0, duration: 0.5,});
                //Vanishes with the institutionhome content
                tl3.to(".Institutionhomecontent", {display: "none", opacity: 0, duration: 0, delay:0, ease: Linear});
                //Return Overlay to original shade
                tl3.to(".darkoverlay", {opacity: 0.05, duration: 4, delay:0, ease: Linear});
                //Vanishe with custom text and button
                tl3.to("#textareaheroinstitution", {x:-10, opacity: 0, duration: 1, delay:-4});
                // Scroll down lottie vanishes
                tl3.to("#scrolldownarrowinstitutional", {opacity: 0, duration: 1, delay:-4});
               // Make the heroselector fixed
                tl3.to(".HeroTypeofUserSelector", {position:"fixed", top:0, delay:-4 ,  duration: 0});
                //return Woman to the screen
                tl3.to(".ladypositioner2", {x: 0, duration: 2, delay:-4, ease: Power3.easeInOut});
                tl3.to(".ladypositioner1", {width: "50vw", duration: 1, delay: -4, ease: Linear}); 
                tl3.to(".lady", {width: "50vw", duration: 1, delay: -4, ease: Linear});    
                tl3.to("#professionalside", {width: "100%", x:0, opacity:1, duration: 1, delay: -4, ease: Linear});
                //return buttons
                tl3.to(".ToVanish", {opacity:1, duration: 0.5, delay:-3, ease: Linear});
                tl3.to(".ToVanish", {display: "block", duration: 0, delay:-3, ease: Linear});
        
        
        }
        




 }

 function professionalbackarrow () { 
        
        if (window.innerWidth <= 600){

                // Back arrow for the professional vanishes        
                tl4.to("#backarrowprofessional", {alpha: 0, duration: 0.5,});
                //Vanishes with the professionalhome content
                tl4.to(".Professionalhomecontent", {display: "none", opacity: 0, duration: 0, delay:0, ease: Linear});
                //Return Overlay to original shade
                tl4.to(".darkoverlay", {opacity: 0.05, duration: 4, delay:0, ease: Linear});
                //Vanishe with custom text and button
                tl4.to("#textareaheroprofessional", {x:-10, opacity: 0, duration: 1, delay:-4});
                // Scroll down lottie vanishes
                tl4.to("#scrolldownarrowprofessional", {opacity: 0, duration: 1, delay:-4});
               // Make the heroselector fixed
                tl4.to(".HeroTypeofUserSelector", {position:"fixed", top:0, delay:-4 ,  duration: 0});
                //return Woman to the screen
                tl4.to(".ladypositioner1", {x: 0, duration: 2, display: "block", delay:-4, ease: Power3.easeInOut});
                tl4.to(".ladypositioner2", {width: "50vw", duration: 1, delay: -4, ease: Linear}); 
                tl4.to(".lady", {width: "50vw", duration: 1, delay: -4, ease: Linear});    
                tl4.to("#institutionside", {width: "100%", x:0, opacity:1, duration: 1, delay: -4, ease: Linear});
                //return buttons
                tl4.to(".ToVanish", {opacity:1, duration: 0.5, delay:-3, ease: Linear});
                tl4.to(".ToVanish", {display: "block", duration: 0, delay:-3, ease: Linear});
              
            
      }
      else
      {
        // Back arrow for the professional vanishes        
        tl4.to("#backarrowprofessional", {alpha: 0, duration: 0.5,});
        //Vanishes with the professionalhome content
        tl4.to(".Professionalhomecontent", {display: "none", opacity: 0, duration: 0, delay:0, ease: Linear});
        //Return Overlay to original shade
        tl4.to(".darkoverlay", {opacity: 0.05, duration: 4, delay:0, ease: Linear});
        //Vanishe with custom text and button
        tl4.to("#textareaheroprofessional", {x:-10, opacity: 0, duration: 1, delay:-4});
        // Scroll down lottie vanishes
        tl4.to("#scrolldownarrowprofessional", {opacity: 0, duration: 1, delay:-4});
       // Make the heroselector fixed
        tl4.to(".HeroTypeofUserSelector", {position:"fixed", top:0, delay:-4 ,  duration: 0});
        //return Woman to the screen
        tl4.to(".ladypositioner1", {x: 0, display: "block", duration: 2, delay:-4, ease: Power3.easeInOut});
        tl4.to(".ladypositioner2", {width: "50vw", duration: 1, delay: -4, ease: Linear}); 
        tl4.to(".lady", {width: "50vw", duration: 1, delay: -4, ease: Linear});    
        tl4.to("#institutionside", {width: "100%", x:0, opacity:1, duration: 1, delay: -4, ease: Linear});
        //return buttons
        tl4.to(".ToVanish", {opacity:1, duration: 0.5, delay:-3, ease: Linear});
        tl4.to(".ToVanish", {display: "block", duration: 0, delay:-3, ease: Linear});
      
      
      }
      


 }

      
        function professionalsideclicked () {       
         
     
                if (window.innerWidth <= 600){
                        tl1.to(".ToVanish", {opacity:0, duration: 0.5, ease: Linear});
                        tl1.to(".ToVanish", {display: "none", duration: 0, ease: Linear});
                        tl1.to("#institutionside", {width: "0%", x:0, opacity:0, duration: 1, delay: -0.5, ease: Linear});
                        tl1.to(".ladypositioner1", {x:100, duration: 2, delay:-1.5, display: "none", ease: Power3.easeInOut});
                        tl1.to(".ladypositioner2", {width: "100vw", duration: 1, delay: -1.5, ease: Linear}); 
                        tl1.to(".lady", {width: "100vw", duration: 1, delay: -1.5, ease: Linear});          
                        tl1.to(".HeroTypeofUserSelector", {position:"absolute", top:0,  duration: 0});
                        tl1.to("#backarrowprofessional", {alpha: 1, duration: 0.5,});                        
                        tl1.to("#scrolldownarrowprofessional", {opacity: 1, duration: 1, delay:-1});
                        tl1.to("#textareaheroprofessional", {x:10, opacity: 1, duration: 1, delay:-1});
                        tl1.to(".Professionalhomecontent", {display: "block", opacity: 1, duration: 0, delay:-0.8, ease: Linear});
                        tl1.to(".darkoverlay", {opacity: 0.2, duration: 4, delay:0, ease: Linear});
              
                }
                else
                {
                        tl1.to(".ToVanish", {opacity:0, duration: 0.5, ease: Linear});
                        tl1.to(".ToVanish", {display: "none", duration: 0, ease: Linear});
                        tl1.to("#institutionside", {width: "0%", x:0, opacity:0, duration: 1, delay: -0.5, ease: Linear});
                        tl1.to(".ladypositioner1", {x:100, duration: 2, delay:-1.5, display: "none", ease: Power3.easeInOut});       
                        tl1.to(".HeroTypeofUserSelector", {position:"absolute", top:0,  duration: 0});
                        tl1.to("#backarrowprofessional", {alpha: 1, duration: 0.5,});
                        tl1.to("#scrolldownarrowprofessional", {opacity: 1, duration: 1, delay:-1});
                        tl1.to("#textareaheroprofessional", {x:10, opacity: 1, duration: 1, delay:-1});
                        tl1.to(".Professionalhomecontent", {display: "block", opacity: 1, duration: 0, delay:-0.5, ease: Linear});
                        tl1.to(".darkoverlay", {opacity: 0.2, duration: 4, delay:0, ease: Linear});
                        
        
                }
        
        
         
          



  }  

  function institutionsideclicked () {     

        if (window.innerWidth <= 600){
                tl2.to(".ToVanish", {opacity:0, duration: 0.5, ease: Linear});
                tl2.to(".ToVanish", {display: "none", duration: 0, ease: Linear});
                tl2.to("#professionalside", {width: "0%", x:0, opacity:0, duration: 1, delay: -0.5, ease: Linear});
                tl2.to(".ladypositioner2", {x:-100, duration: 2, delay:-1.5, ease: Power3.easeInOut});
                tl2.to(".ladypositioner1", {width: "100vw", duration: 1, delay: -1.5, ease: Linear}); 
                tl2.to(".lady", {width: "100vw", duration: 1, delay: -1.5, ease: Linear});          
                tl2.to(".HeroTypeofUserSelector", {position:"absolute", top:0,  duration: 0});
                tl2.to("#backarrowinstitution", {alpha: 1, duration: 0.5,});
                tl2.to("#scrolldownarrowinstitutional", {opacity: 1, duration: 1, delay:-1});
                tl2.to("#textareaheroinstitution", {x:10, opacity: 1, duration: 1, delay:-1});
                tl2.to(".Institutionhomecontent", {display: "block", opacity: 1, duration: 0, delay:-0.8, ease: Linear});
                tl2.to(".darkoverlay", {opacity: 0.2, duration: 4, delay:0, ease: Linear});
        }
        else
        {
                tl2.to(".ToVanish", {opacity:0, duration: 0.5, ease: Linear});
                tl2.to(".ToVanish", {display: "none", duration: 0, ease: Linear});
                tl2.to("#professionalside", {width: "0%", x:0, opacity:0, duration: 1, delay: -0.5, ease: Linear});
                tl2.to(".ladypositioner2", {x:-100, duration: 2, delay:-1.5, ease: Power3.easeInOut});       
                tl2.to(".HeroTypeofUserSelector", {position:"absolute", top:0,  duration: 0});
                tl2.to("#backarrowinstitution", {alpha: 1, duration: 0.5,});
                tl2.to("#scrolldownarrowinstitutional", {opacity: 1, duration: 1, delay:-1});
                tl2.to("#textareaheroinstitution", {x:10, opacity: 1, duration: 1, delay:-1});
                tl2.to(".Institutionhomecontent", {display: "block", opacity: 1, duration: 0, delay:-0.5, ease: Linear});
                tl2.to(".darkoverlay", {opacity: 0.2, duration: 4, delay:0, ease: Linear});
                

        }


     

        
          
}  



  return (

       <div className="HeroTypeofUserSelector">   
       <div className="fixedlogo">
       <img className="logomenu" src={logo} alt="Logo"/>
       </div>
   

     <div className="bgzao"></div>
        <div className="floater1"></div>
        <div className="floater2"></div>
        <div className="floater3"></div>
        <div className="floater4"></div>
        <div className="floater5"></div>
        <div className="floater6"></div>
        <div className="floater7"></div>
   

                  <div className="leftrightwrapper">


                  <div className="darkoverlay"></div>

                            <div className="left" id="professionalside">

                                    <div className="ladypositioner ladypositioner2">
                                
                                                 <div className="professionallady lady"></div>

                                    </div>



                                    <div className="backarrow" id="backarrowprofessional" onClick={professionalbackarrow}></div>
                                    
                                   <div className="clickablearea"  onClick={professionalsideclicked}>   
                                    <div className="textareahero" id="textareaheroprofessional">
                                            <h1>Buscamos Oportunidades</h1>
                                            <h3>Alcance mais longe com a Quemindiq</h3>
                                            <a href="/manutencao">   <Button className="Button">Entre em Contato Agora!</Button> </a>
                                            </div>    
                                    <Button className="Button ToVanish">Sou Profissional</Button>  
                                    <div className="scrolldownarrowbar" id="scrolldownarrowprofessional">
                                    <Extralottie1 />
                                    </div>
                                    </div>       
                            </div>

                            <div className="right" id="institutionside">

                                    <div className="backarrow" id="backarrowinstitution" onClick={institutionbackarrow}></div>
                                        <div className="ladypositioner1 ladypositioner">
                            
                                                
                                                 <div className="institutionlady lady"></div>                                                
                                                </div>       
                                        
                                    

                                    <div className="clickablearea"  onClick={institutionsideclicked}>

                                    <div className="textareahero" id="textareaheroinstitution">
                                            
                                            <h1>Buscamos Profissionais</h1>
                                            <h3>Sua maior aliada para SELEÇÕES CUSTOMIZADAS</h3>
                                      
                                            <a href="/contato"><Button className="Button">Entre em Contato Agora!</Button></a>
                                            </div>    

                                    <Button className="Button ToVanish">Sou Instituição</Button>
                                    <div className="scrolldownarrowbar" id="scrolldownarrowinstitutional">
                                    <Extralottie1 />
                                    </div>

                                    </div>  

                            </div>
                  </div>

           



          
      
    </div>
  );
}

export default HeroTypeofUserSelector;
