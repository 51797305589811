import React from 'react'
import gsap from 'gsap'
import Logo from '../images/logo_footer.png'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useState, useRef } from 'react';
import {useNavigate}  from 'react-router-dom'
import { useSelfPerfilApiContext } from '../contexts/SelfPerfilAPIContext'


const BackgroundShadeBall = ({opacityClass, positionClass}) =>{
    return(
        <div className={`w-3/5 h-3/5 absolute bg-black rounded-full ${positionClass} ${opacityClass}`}></div>
    )
}

export default function LoginPage({destinationFeature, mode}) {

  const [showPassword, setshowPassword] = useState(false)

  const navigate = useNavigate()
  const { SelfSvg } = useSelfPerfilApiContext()

  const credentials = useRef({})

  const handleLoginButtonClick = () => {
   
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
   
    if (mode === 'noLogin'){

      if (re.test(credentials.current.email)) {
        navigate('/selfperfil/', {state:{email: credentials.current.email}})
      }

    }

   

   
  
  }


  return (
    <div className={`w-screen h-screen max-w-screen-xl flex items-center justify-center`}>
        <div className={`w-1/2 relative overflow-hidden h-full hidden lg:flex items-center justify-center bg-blue-500`}>
                <img className={`absolute top-4 left-4`} src={Logo} alt=""/>
                <div className={`absolute w-3/5 h-3/5 bottom-0 rounded-full right-0 transform translate-x-1/2 translate-y-1/2 bg-black opacity-10`}></div>
                <BackgroundShadeBall opacityClass={`opacity-20`} positionClass={`top-0 right-0 transform translate-x-1/2`} />
                <BackgroundShadeBall opacityClass={`opacity-10`} positionClass={`top-0 right-0 transform -translate-y-1/2`} />
                <BackgroundShadeBall opacityClass={`opacity-20`} positionClass={`bottom-0  left-0 transform -translate-x-1/2`} />
                <BackgroundShadeBall opacityClass={`opacity-10`} positionClass={`bottom-0 left-0 transform translate-y-1/2`} />
              
        </div>
        <div className={`w-full flex items-center justify-center h-full lg:w-1/2`}>
              <div className={`p-4 rounded-md shadow-lg flex flex-col gap-8 items-start justify-center`}>
              <SelfSvg/>
               <div className={`flex flex-col w-full items-start justify-center gap-4`}>
                 
                  <span className={`text-2xl font-bold`}>
                    {mode=== "noLogin" && "Acessar"}
                    {mode=== "login" && "Entrar"}
                   </span>

                  <span>
                  {mode=== "noLogin" && "Insira seu e-mail para começar."}
                  {mode=== "login" && "Faça Login com a sua Conta."}
                  </span>

                  <TextField onChange={(e)=>{credentials.current.email=e.target.value}} type={'email'} fullWidth id="outlined-basic" label="Email" variant="outlined" />
                 
                   {mode === 'noLogin' 
                   ? null 
                   :
                  <>
                       <div className={`relative w-full`}>
                      <TextField  fullWidth type={ showPassword ? "text" : "password"} id="outlined-basic" label="Senha" variant="outlined" />
                      <div onClick={()=>{setshowPassword(!showPassword)}} className={`w-10 h-10 text-gray-500 hover:text-gray-700 cursor-pointer transition-all duration-150 ease-in-out hover:bg-black/10 rounded-full flex items-center  justify-center absolute top-1/2 right-2 transform -translate-y-1/2`}>
                      {                      showPassword
                        ?
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd"></path><path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"></path></svg>
                        :
                        <svg class="w-6 h-6 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path><path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd"></path></svg>
                      }
                      </div>
                    </div>
                    <span className={`cursor-pointer`}>Esqueceu a senha?</span>
                  </>
                   }
                
               </div>
                <div className={`flex w-full items-center justify-between gap-4`}>
                    { mode=== "login" && <Button variant="contained"><div className={`px-4`}>Registrar</div></Button> }
                    <Button fullWidth={true} onClick={()=>{handleLoginButtonClick()}
                      } variant="contained" color="primary">
                    {mode=== "noLogin" && "Começar"}
                    {mode=== "login" && "Login"}
                    </Button>
                    
                </div>
              </div>
        </div>
    </div>
  )
}
