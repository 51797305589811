import React from 'react'

export default function SmallNumberDisplay({oneLineSummaryOfChanges, id, title, icon, number}) {
  return (
    <div className={`w-56 relative p-4 bg-gray-700 rounded-xl shadow-xl flex flex-col items-stretch justify-center gap-2`}>
      
        <div className={`flex flex-col items-end justify-center gap-2 border-b border-b-gray-200 border-opacity-50`}>
            <span className={`text-gray-200 font-sans font-light text-base`}>{title}</span>
            <span className={`text-gray-100 font-sans font-bold text-lg`}>{number}</span>
        </div>

        <div className={``}>
          <span className={`text-gray-100`}>{oneLineSummaryOfChanges}</span>
        </div>
        
        {/* absolute div below */}
        <div className={`absolute rounded-lg -top-2 left-5 w-14 h-14 bg-cyan-500`}>
        </div>
    </div>
  )
}
