import React, {useRef} from 'react'
import SuccessLottie from '../components/lottiecomponents/SuccessLottie'
import SelfPerfilResults from '../components/SelfPerfilFrontEnd/SelfPerfilResults/SelfPerfilResults'
import {useLocation,useParams} from 'react-router-dom'

export default function MySelfPerfilResult() {

  const params = useParams()  
  const location  = useLocation()   
  const answers = useRef(location.state ? location.state['answers'] : [])
  const email = useRef(location.state ? location.state['email'] : null)

  return (
    <div className={`flex max-w-screen-xl p-4 min-h-screen w-full flex-col items-center justify-center gap-2`}>   
       <SelfPerfilResults email={email} resultId={params.resultId} answers={answers} />
       {/* <span className={`text-lg font-sans`}>Parabens @userName! Suas respostas foram recebidas com sucesso!</span> 
       <SuccessLottie/>      
       <span>Aguarde pois seu empregador irá entrar em contato com você em breve para lhe entregar os resultados!</span> */}
    </div>
  )
}
